<!--TODO: Remove component from vmx -->
<template>
  <div>
    <div
      :class="headerTheme"
      id="header"
    >
      <header
        :class="this.$route.name"
        class="vmx-header"
      >
        <div
          v-if="initialized"
          :class="
            programHeight
              ? 'bannerClasses vmx-header__banner__max__height__program '
              : 'bannerClasses'
          "
          class="vmx-header__banner"
        >
          <b-navbar-toggle
            v-if="!menuDisabled"
            @click="switchIconClass"
            target="nav-collapse"
            :class="[!collapseNav ? 'navbar-toggler-dynamic' : '']"
          >
            <template #default>
              <div v-if="newMenuIcon">
                <div class="navMenu__Icon">
                  <span
                    v-show="menuIconVariant === 'collapsed'"
                  ><i
                    id="menu-icon-collapsed"
                    :class="menuIconCollapsed"
                  /></span><span
                    v-show="menuIconVariant === 'not-collapsed'"
                  ><i
                    id="menu-icon-not-collapsed"
                    :class="menuIconNotCollapsed"
                  /></span>
                </div>
                <span
                  class="navMenu__IconTitle"
                  v-if="menuIconTitle"
                >{{
                  menuIconTitle
                }}</span>
              </div>
              <div
                v-else
                class="navbar-toggler-icons"
              >
                <span class="navbar-toggler-icon" />
                <span class="navbar-toggler-icon" />
                <span class="navbar-toggler-icon" />
              </div>
            </template>
          </b-navbar-toggle>

          <div :class=" headerVmContainer ? 'vm-container' : 'vm-container no-vm-container' ">
            <div
              :class="[
                !collapseNav ? 'vmx-header__banner__images-dynamic' : '',
                programHeight ? 'vmx-header__banner__max__height__program ' : ''
              ]"

              class="vmx-header__banner__images"
            >
              <template v-if="timeEnabled">
                <div class="vmx-header__banner-container">
                  <div
                    v-if="headerModel.EventLogo"
                    class="vmx-header__banner__image-1"
                  >
                    <a>
                      <img
                        style="cursor: pointer"
                        @click.prevent="showHome()"
                        v-lazy="headerModel.EventLogo"
                      />
                    </a>
                  </div>

                  <div class="vmx-header__banner__times">
                    <template v-if="timezoneText">
                      <div class="vmx-header__banner__timezone-text">
                        {{ $t("VMX.header_timezone_text") }}
                      </div>
                    </template>

                    <template v-if="timeEnabled">
                      <div class="vmx-header__banner__time">
                        {{ dateNow }}
                      </div>
                    </template>
                  </div>
                </div>
              </template>

              <template v-else>
                <div
                  v-if="headerModel.EventLogo || logoAsText"
                  class="vmx-header__banner__image-1"
                >
                  <span
                    v-if="event === 'dgu2021'"
                    class="header-text-logo"
                  >
                    DGU 2021
                  </span>
                  <a v-else>
                    <img
                      style="cursor: pointer"
                      @click.prevent="showHome()"
                      v-lazy="headerModel.EventLogo"
                    />
                    <div
                      v-if="logoAsText"
                      class="vmx-header__banner__logo-content"
                      v-html="logoAsText"
                      style="cursor: pointer"
                      @click.prevent="showHome()"
                    />
                  </a>
                </div>
              </template>

              <div class="vmx-header__banner__nav">
                <ul>
                  <b-nav-item
                    v-for="(item, index) in headerModel.menuItems"
                    :key="index"
                    v-bind="navItemProps(item)"
                    @click="tracking(displayName(item))"
                  >
                    {{ displayName(item) }}
                  </b-nav-item>
                </ul>
              </div>

              <template v-if="bannerCustomerLogo">
                <div class="vmx-header__banner__image-2">
                  <img
                    @click="openCustomLogoLink(bannerCustomerLogo.url)"
                    v-lazy="bannerCustomerLogo.image"
                    :style="bannerCustomerLogo.url ? 'cursor:pointer' : null"
                  />
                </div>
              </template>

              <div
                class="vmx-header__banner__menu"
                :class="[!collapseNav ? 'vmx-header__banner__menu-dynamic' : '']"
              >
                <template v-if="headerTheme === headerThemes.theme2">
                  <div class="vmx-header__banner__time">
                    {{ dateNow }}
                  </div>
                </template>
                <ul>
                  <template v-if="window.width > 991 && loginStatus">
                    <!--TODO: Remove from code: bannerMenuList  -->
                    <li
                      v-for="(item, index) in (headerConfig && headerConfig.bannerMenuList || bannerMenuList)"
                      :key="index"
                      :class="item.tooltip ? 'vmx-header__banner--item-tooltip' : null"
                    >
                      <template v-if="item.tooltip">
                        <span class="vmx-header__banner--item-tooltip-text">{{ item.tooltip }}</span>
                      </template>
                      <a
                        :href="!menuDisabled ? item.url : null"
                        :target="item.target ? item.target : '_blank'"
                        @click="tracking(item.name, item.module)"
                        :class="item.class"
                        :id="item.id"
                      >

                        <img
                          v-if="item.img"
                          :src="item.img"
                          alt="bannerMenuLogo"
                        />
                        {{ item.name }}
                      </a>
                    </li>
                  </template>
                  <li
                    v-if="loginStatus"
                    class="vmx-header__banner__menu__profile"
                  >
                    <a
                      href
                      @click.prevent="profile()"
                    >{{
                      $t("VMX.user_my_profile")
                    }}</a>
                    <span class="vmx-header__pipe">|</span>
                  </li>
                  <li
                    v-if="loginStatus"
                    class="vmx-header__banner__menu__logout"
                    :class="bannerMenuList && bannerMenuList.tooltip ? 'vmx-header__banner--item-tooltip' : null"
                  >
                    <template v-if="bannerMenuList && bannerMenuList.tooltip">
                      <span class="vmx-header__banner--item-tooltip-text">{{ $t("VMX.user_logout") }}</span>
                    </template>
                    <a
                      href
                      @click.prevent="logout()"
                    >{{
                      $t("VMX.user_logout")
                    }}</a>
                  </li>
                  <li
                    v-if="!loginStatus"
                    class="vmx-header__banner__menu__login"
                  >
                    <a
                      href
                      @click.prevent="login()"
                    >{{
                      $t("VMX.user_login")
                    }}</a>
                  </li>
                  <li
                    class="vmx-header__banner__menu__login"
                    v-if="qrScanner"
                  >
                    <a
                      href
                      @click.prevent="qrScan()"
                    >Scan QR</a>
                  </li>
                  <span
                    v-if="headerSocialIcons"
                    class="vmxHeader-socialMediaIcons"
                  >
                    <a
                      v-for="item in headerIcons"
                      :key="item.icon"
                      :href="item.link"
                      :target="'_blank'"
                    ><i :class="item.icon" />
                      <!-- <font-awesome-icon
                  size="1x"
                  :icon="['fas', 'arrow-right']"
                /> -->
                    </a>
                  </span>
                  <li
                    v-if="
                      headerModel.EventLanguages &&
                        headerModel.EventLanguages.length > 1
                    "
                  >
                    <a
                      v-for="(lang, index) in headerModel.EventLanguages"
                      :href="
                        `/${
                          $route.path.split('/')[1]
                        }/${instance}/${event}/${lang}`
                      "
                      :key="index"
                    >
                      <img
                        v-lazy="getLanguageIcon(lang)"
                        :alt="lang"
                      />
                    </a>
                  </li>
                </ul>
                <div
                  v-if="loginStatus && initialsEnabled"
                  class="header-info-avatar"
                >
                  {{ initials }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!menuDisabled"
          class="navbar-background"
          id="vmx-header-nav"
          :class="hasBackground"
        >
          <div
            v-if="initialized"
            :class="headerTheme === 'header-theme__three' ? '' : 'vm-container'"
          >
            <b-navbar
              :toggleable="headerTheme === 'header-theme__three' ? 'xl' : 'lg'"
              variant="vmx-navbar"
            >
              <b-collapse
                id="nav-collapse"
                v-model="showCollapse"
                :is-nav="collapseNav"
              >
                <b-navbar-nav
                  class="navbar-nav__First"
                  id="navbar-nav__First"
                  :class="[!collapseNav ? 'navbar-nav__dynamic' : '']"
                >
                  <!--                <b-nav-item-->
                  <!--                  v-for="(item, index) in headerModel.menuItems"-->
                  <!--                  :key="index"-->
                  <!--                  v-bind="navItemProps(item)"-->
                  <!--                  @click="tracking(displayName(item))"-->
                  <!--                >-->
                  <!--                  {{ displayName(item) }}-->
                  <!--                </b-nav-item>-->
                  <!--TODO: headerModel.menuItems Remove from code -->
                  <li
                    :class="`nav-item nav-item_${index}`"
                    v-for="(item, index) in (headerConfig && headerConfig.menuItems || headerModel.menuItems)"
                    :key="index"
                  >
                    <template v-if="navItemProps(item).href">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="additionalClickHref(item)"
                        class="nav-link"
                        v-bind="navItemProps(item)"
                        v-html="displayName(item)"
                      />
                    </template>
                    <template v-if="navItemProps(item).to">
                      <span @click="additionalClick(item)">
                        <router-link
                          class="nav-link"
                          v-bind="navItemProps(item)"
                          v-html="displayName(item)"
                          :class="navItemProps(item).active ? 'active' : null"
                        />
                      </span>
                    </template>
                  </li>
                </b-navbar-nav>
                <b-navbar-nav
                  class="navbar-nav__Second"
                  id="navbar-nav__Second"
                  :class="[!collapseNav ? 'navbar-nav__dynamic' : '']"
                >
                  <span
                    v-for="(item, index) in (bannerMenuList || (headerConfig && headerConfig.bannerMenuList))"
                    :key="index"
                  >
                    <b-nav-item
                      v-if="loginStatus"
                      :href="item.url"
                      class="nav-item__custom-link"
                      :target="item.target ? item.target : '_blank'"
                      @click="tracking(item.name, item.module)"
                      :class="item.class"
                      :id="item.id"
                    >
                      <i
                        v-if="item.icon"
                        :class="item.icon"
                      />
                      <img
                        v-if="item.img"
                        :src="item.img"
                        alt="bannerMenuLogo"
                      />{{ item.name }}
                    </b-nav-item>
                  </span>
                  <b-nav-item
                    v-if="loginStatus"
                    class="nav-item__user"
                    @click.prevent="profile()"
                  >
                    {{ $t("VMX.user_my_profile") }}
                  </b-nav-item>
                  <b-nav-item
                    v-if="loginStatus"
                    :class="industryOnly ? null : 'nav-item__logout'"
                    @click.prevent="logout()"
                  >
                    {{ $t("VMX.user_logout") }}
                  </b-nav-item>
                  <b-nav-item
                    v-if="!loginStatus"
                    class="nav-item__login"
                    @click.prevent="login()"
                  >
                    {{ $t("VMX.user_login") }}
                  </b-nav-item>
                  <b-nav-item
                    class="nav-item__login"
                    v-if="qrScanner"
                    @click.prevent="qrScan()"
                  >
                    Scan QR
                  </b-nav-item>
                  <li
                    class="nav-item__language"
                    v-if="
                      headerModel.EventLanguages &&
                        headerModel.EventLanguages.length > 1
                    "
                  >
                    <a
                      v-for="(lang, index) in headerModel.EventLanguages"
                      :href="
                        `/${
                          $route.path.split('/')[1]
                        }/${instance}/${event}/${lang}`
                      "
                      :key="index"
                    >
                      <img
                        v-lazy="getLanguageIcon(lang)"
                        :alt="lang"
                      />
                    </a>
                  </li>
                </b-navbar-nav>
              </b-collapse>
            </b-navbar>
          </div>
        </div>
      </header>
      <access-denied
        v-if="!hasAccess && onAccessDeniedDialogClose"
        @close="onAccessDeniedDialogClose = false"
      />
    </div>
    <modal
      class="eular-footer-modal"
      :show="eularModalFlip.show"
      @close="eularModalFlip.show = false"
    >
      <iframe
        frameborder="0"
        width="100%"
        height="800"
        src="https://virtualcongress.eular.org/flipbook/congressnews/index.html#page=1"
        scrolling="no"
        marginwidth="0"
        marginheight="0"
        allowFullScreen
        id="flipid"
      />
      <a
        class="eular-pdf-download"
        href="https://distribution-congress.eular.org/from.storage?file=Mv%2b7a5KpyMAB8pszaFiVg2xUaN182pjJSvn7SC0VSNo%3d"
        target="_blank"
        download
      >Download</a>
    </modal>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import trackingApi from '~/api/tracking'
import AccessDenied from 'm-anage.com.vmx-components/src/Dialogs/AccesDeniedDialog'
import modal from 'm-anage.com.vmx-components/src/Modal'
import userState from '../states/user'
import config from '../config'
import utils from '../utilities/utils'
import accessControl from '../utilities/accessControl'
import utilsComponents from 'm-anage.com.vmx-components/src/utilities/'


export default {
  name: 'VMXHeader',
  inject: {
    TrackingUtil: {
      type: Function,
    },
  },
  components: {
    AccessDenied,
    modal,
  },
  data() {
    return {
      programHeight: false,
      dateNow: '',
      headerThemes: {
        theme1: 'header-theme__one',
        theme2: 'header-theme__two',
        theme3: 'header-theme__three',
      },
      onAccessDeniedDialogClose: false,
      hasAccess: null,
      isaad: false,
      menuIconVariant: 'collapsed',
      newMenuIcon: false,
      menuIconCollapsed: 'fa fa-bars',
      // headerConfig: {
      //   vmContainer: true,
      //   headerLogo: '',
      //   headerBackground: '',
      //   bannerCustomerLogo: {
      //     image: '',
      //     url: '',
      //   },
      //   menu: {
      //     menuIconTitle: 'MENU',
      //     menuIconNotCollapsed: 'fas fa-times',
      //     menuIconCollapsed: 'fa fa-bars',
      //     newMenuIcon: true,
      //   },
      //   customHeaderUrl: '',
      //   customProfileUrl: '',
      //   industryOnly: false,
      //   headerTimezoneText: false,
      //   timeEnabled: false,
      //   initialsEnabled: false,
      //   bannerMenuList: {
      //     customLinkOne: {
      //       name: 'Helpdesk',
      //       target: '__self',
      //       url: '/contact/escrs/escrswm22/en-GB',
      //     },
      //   },
      //   menuItems: [
      //     {
      //       Displayname: '',
      //       Icon: '',
      //       Url: '',
      //       Open: '_self',
      //       roles: [],
      //       moduleActiveFrom: '',
      //       moduleActiveTill: '',
      //       isVisible: true,
      //     },
      //   ],
      // },
      menuIconNotCollapsed: 'fas fa-times',
      menuIconTitle: '',
      storedWidth: null,
      headerSocialIcons: false,
      headerIcons: [],
      showCollapse: false,
      headerVmContainer: false,
      collapseNav: true,
      isScrolled: false,
      logoAsText: null,
      windowWidth: window.innerWidth,
      eularModalFlip: {
        show: false,
      },
      window: {
        width: 0,
        height: 0,
      },
    }
  },
  async created() {
    await this.loadConfig()

    if (this.signalRConnection) {
      this.$accountHub.$on('remote-logout', (params) => {
        if('uuid' in params && 'modal' in params && this.openSignalRModal && userState.getUser(this.instance).uuid === params.uuid && params.modal) {
          this.emitOpenSignalRModal()
        } else {
          this.logout()
        }
      })
    }
    this.$root.$on('logout-user-device', async () => {
      await this.$accountHub.startSignalRAccount(this.instance, this.event, userState.getUser(this.instance).accessToken, this.signalRConnectionAmount, false, userState.getUser(this.instance).uuid)
      this.emitCloseSignalRModal()
      this.logout()
    })

    this.$root.$on('continue-user-device', async () => {
      await this.$accountHub.startSignalRAccount(this.instance, this.event, userState.getUser(this.instance).accessToken, this.signalRConnectionAmount, true, userState.getUser(this.instance).uuid)
      this.emitCloseSignalRModal()
    })

    window.addEventListener('resize', this.onResize)
    const headerConfig = this.configForKey('app_config').config[0].defaults
      ?.header
    if (headerConfig?.menu) {
      if (headerConfig.menu.newMenuIcon) {
        this.newMenuIcon = headerConfig.menu.newMenuIcon
      }
      if (headerConfig.menu.menuIconCollapsed) {
        this.menuIconCollapsed = headerConfig.menu.menuIconCollapsed
      }
      if (headerConfig.menu.menuIconNotCollapsed) {
        this.menuIconNotCollapsed = headerConfig.menu.menuIconNotCollapsed
      }
      if (headerConfig.menu.menuIconTitle) {
        this.menuIconTitle = headerConfig.menu.menuIconTitle
      }

      if (headerConfig.headerIcons) {
        this.headerSocialIcons = true
        this.headerIcons = headerConfig.headerIcons
      }
    }
    if (headerConfig?.headerLogo?.logoAsText) {
      this.logoAsText = headerConfig.headerLogo.logoAsText
    }

    if (headerConfig?.vmContainer) {
      this.headerVmContainer = headerConfig.vmContainer
    }
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  computed: {
    ...mapGetters({
      headerModel: 'header/model',
      configForKey: 'config/configForKey',
      user: 'user/user',
      event: 'instancesState/getEvent',
      language: 'instancesState/getLanguage',
      instance: 'instancesState/getInstance',
      menuDisabled: 'instancesState/getMenuDisabled',
      isAppContainer: 'instancesState/getIsAppContainer',
    }),
    headerTheme() {
      let theme = this.headerModel?.theme
      if (this.backendConfig?.defaults.header?.theme) {
        theme = this.backendConfig.defaults.header.theme
      }
      return utils.getObjKey(theme, this.headerThemes)
    },
    signalRConnection() {
      return this.configForKey('app_config').config[0].AccountHub?.signalRConnection ? this.configForKey('app_config').config[0].AccountHub.signalRConnection : false
    },
    signalRConnectionAmount() {
      return this.configForKey('app_config').config[0].AccountHub?.signalRConnectionAmount ? this.configForKey('app_config').config[0].AccountHub.signalRConnectionAmount : 0
    },
    openSignalRModal() {
      return this.configForKey('app_config').config[0].AccountHub?.openSignalRModal ? this.configForKey('app_config').config[0].AccountHub.openSignalRModal : false
    },
    config() {
      return this.configForKey('industry_config').config
    },
    backendConfig() {
      return this.configForKey('app_config').config[0]
    },
    headerConfig() {
      if (this.config.Header) return this.config.Header
      return null
    },
    landingPages() {
      return this.backendConfig.defaults?.landings
    },
    qrScanner() {
      return this.backendConfig.qrScanner && this.isAppContainer
    },
    activePage() {
      return this.$route.name
    },
    activeRoute() {
      return this.$route
    },
    initialized() {
      if (window.location.href.indexOf('noheader') !== -1 || localStorage.getItem('noheader') === 'true') {
        setTimeout(() => {
          localStorage.setItem('noheader', true)
          document.getElementById('header').style.display = 'none'
          document.getElementById('page-footer').style.display = 'none'
        }, 100)
        this.isaad = true
      }
      return this.headerModel !== null
    },
    // bannerClasses() {
    //   const classes = []
    //   if (this.initialized && this.bannerCustomerLogo == null) {
    //     classes.push('vmx-header__banner--one-image')
    //   }
    //   return classes
    // },
    bannerCustomerLogo() {
      let obj = null
      if (this.backendConfig) {
        obj = this.backendConfig.defaults?.bannerCustomerLogo
      }

      return obj
    },
    bannerMenuList() {
      let list = null
      if (this.backendConfig) {
        list = this.backendConfig.defaults?.bannerMenuList
      }
      return list
    },
    loginStatus() {
      return !!userState.getUser(this.instance)
    },
    externalAuth() {
      if (this.backendConfig.defaults?.externalAuth) {
        return this.backendConfig.defaults.externalAuth.externalLogin
      }
      return false
    },
    initials() {
      const firstNameInitial = this.$cookies.get('firstname') ? this.$cookies.get('firstname').substring(0, 1) : 'J'
      const lastNameInitial = this.$cookies.get('lastname') ? this.$cookies.get('lastname').substring(0, 1) : 'D'
      return `${firstNameInitial}${lastNameInitial}`
    },
    initialsEnabled() {
      if (this.backendConfig.defaults && this.backendConfig.defaults.headerInitialsEnabled) {
        return this.backendConfig.defaults.headerInitialsEnabled
      }
      return false
    },
    timeEnabled() {
      if (this.backendConfig.defaults && this.backendConfig.defaults.headerTimeEnabled) {
        return this.backendConfig.defaults.headerTimeEnabled
      }
      return false
    },
    timezoneText() {
      if (this.backendConfig.defaults && this.backendConfig.defaults.headerTimezoneText) {
        return this.backendConfig.defaults.headerTimezoneText
      }
      return false
    },
    industryOnly() {
      if (this.backendConfig.defaults && this.backendConfig.defaults.industryOnly) {
        return this.backendConfig.defaults.industryOnly
      }
      return false
    },
    customHeaderUrl() {
      if (this.backendConfig.defaults && this.backendConfig.defaults.customHeaderUrl) {
        return this.backendConfig.defaults.customHeaderUrl
      }
      return null
    },
    customProfileUrl() {
      if (this.backendConfig.defaults && this.backendConfig.defaults.customProfileUrl) {
        return this.backendConfig.defaults.customProfileUrl
      }
      return null
    },
    hasBackground() {
      let stickyStyle = null
      if (this.isScrolled) {
        stickyStyle = 'sticky-header'
      }
      return stickyStyle
    },
  },
  methods: {
    ...mapActions({
      loadHeaderModel: 'header/loadModel',
      loadConfig: 'config/loadConfig',
    }),
    parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    },
    emitOpenSignalRModal() {
      this.$root.$emit('open-signalr-logout-modal')
    },
    emitCloseSignalRModal() {
      this.$root.$emit('close-signalr-logout-modal')
    },
    onResize(e) {
      this.windowWidth = window.innerWidth
      if (this.windowWidth >= 992) {
        this.collapseNav = true
      }
    },
    qrScan() {
      window.open('/qrScan', '_self')
    },
    switchIconClass() {
      if (this.menuIconVariant === 'collapsed') {
        this.menuIconVariant = 'not-collapsed'
      } else {
        this.menuIconVariant = 'collapsed'
      }
    },
    tracking(name, module) {
      if (utils.advancedTracking()) {
        this.TrackingUtil.trackEvent(
          'HeaderMenu',
          'Click',
          utils.advancedTrackingFormat(
            this.user.UserId,
            'Event',
            'MenuItem',
            name,
          ),
        )
      } else {
        this.TrackingUtil.trackEvent('HeaderMenu', 'Click', name)
      }
    },
    checkAccess(url, target) {
      this.hasAccess = this.user ? accessControl.accessControlUrl(url) : true
      this.onAccessDeniedDialogClose = this.hasAccess
        ? window.open(url, target)
        : true
    },
    eventTime() {
      this.dateNow = dayjs().format(`DD MMMM YYYY, ${utilsComponents.timeFormat()}`)
    },
    additionalClickHref(item) {
      trackingApi.trackCustomAction('vmx-header-link', item.Name, window.location)
      if (item && item.Name !== 'WELCOME') {
        this.checkAccess(item.Url, item.Open)
      } else {
        localStorage.removeItem('hasSeenSplashscreen')
        window.location.href = `${window.location.origin}/home/${this.instance}/${this.event}/${this.language}/`
      }
    },
    additionalClick(item) {
      this.tracking(this.displayName(item))
      const pages = ['PosterPage', 'IndustrySymposiaPage', 'PresentationSearchPage', 'FacultySearchPage', 'PagSearchPage', 'AbstractSearchPage']
      if (pages.includes(this.$route.name)) {
        this.$root.$emit('rerender')
      }
    },
    openCustomLogoLink(link) {
      if (utils.isUrl(link)) {
        window.location.href = link
      } else if (utils.isObject(link)) {
        this.$router.push(utils.appendDefaultParamsToRoute(link))
      } else {
        this.$router.push(utils.getRouteWithDefaultParams(link))
      }
    },
    showHome() {
      this.tracking('home')
      if (this.industryOnly) {
        window.location.href = this.customHeaderUrl
      } else {
        localStorage.setItem('hasSeenSplashscreen', true)
        window.open(
          `${window.location.origin}/home/${this.instance}/${this.event}/${this.language}/`,
          '_self',
        )
      }
    },
    isActive(pageName, resolveUrl, resolveQuery) {
      const page = config.getheaderConfig(pageName)
      const routeResolve = this.activeRoute.path.split('/')
      const queryResolve = this.activeRoute.query
      // IndustryOnly-----------------------------------------------------------
      if (this.industryOnly) {
        if (
          this.backendConfig.defaults.industryCustomPages
          && routeResolve[5] === resolveUrl[5]
          && this.backendConfig.defaults.industryCustomPages.includes(routeResolve[5])
        ) {
          return true
        }
        if (
          routeResolve[6] !== undefined
          && routeResolve[6] === resolveUrl[6]
        ) {
          return true
        }
        if (
          page === 'IndustryLandingPage'
          && this.backendConfig.defaults.industryPages
          && (this.backendConfig.defaults.industryPages.includes(
            this.activeRoute.params.page,
          )
            || this.activeRoute.params.page === undefined)
        ) {
          return true
        }
        if (!this.backendConfig.defaults.industryPages) {
          return this.activePage === page
        }
        //---------------------------------------------------------------------
      } else {
        if (
          page === 'ProgramPage'
          && this.activePage === 'ProgramLandingPage'
        ) {
          return true
        }
        if (
          resolveUrl?.name === this.activeRoute.name
          && utils.shallowEqual(resolveUrl.params, this.activeRoute.params)
          && !resolveQuery
        ) {
          return true
        }
        if (
          routeResolve[6] !== undefined
          && routeResolve[6] === resolveUrl[6]
        ) {
          return true
        }
        if (
          resolveQuery !== undefined
          && utils.shallowEqual(queryResolve, resolveQuery)
        ) {
          return true
        }
        if (
          resolveQuery === undefined
          && Object.keys(queryResolve).length > 0
        ) {
          return false
        }
        if (routeResolve[5] === undefined) {
          return this.activePage === page
        }
      }
      return false
    },
    navItemProps(item) {
      const resolveUrl = item.Url.split('/')
      const props = {
        active: this.isActive(item.Name, resolveUrl),
      }
      if (utils.isUrl(item.Url)) {
        props.href = item.Url
        props.target = item.Open
      } else if (item.Url.startsWith('{')) {
        const obj = JSON.parse(item.Url)
        props.to = utils.appendDefaultParamsToRoute(obj)
        props.active = obj.query
          ? this.isActive(item.Name, obj, obj.query)
          : this.isActive(item.Name, obj)
      } else {
        let name = config.getheaderConfig(item.Name)
        let page = ''
        // TODO: make dynamically
        // TODO: Remove from code:(this.landingPages && this.landingPages.programmeLanding)
        if (
          ((this.headerConfig?.landings && this.headerConfig.landings.programmeLanding)
          || (this.landingPages && this.landingPages.programmeLanding))
          && name === 'ProgramPage'
        ) {
          name = 'ProgramLandingPage'
        }
        props.to = utils.getRouteWithDefaultParams(name)
        if (item.Url.startsWith('/')) {
          const [, urlName, , , , urlPage] = resolveUrl
          name = urlName
          page = urlPage
          props.to = utils.getRouteWithDefaultParams(name, { page })
          props.active = this.isActive(item.Name, resolveUrl)
        } else {
          props.to = utils.getRouteWithDefaultParams(name)
        }
      }
      return props
    },
    displayName(item) {
      return item.Displayname ? item.Displayname : item.Name
    },
    logout() {
      this.tracking('logout')
      this.$cookies.remove('accesOnDays')
      this.$cookies.remove('tickets')
      this.$cookies.remove('hcp')
      userState.unsetUser(this.instance)
      this.$store.commit('instancesState/setToken', null)
      localStorage.removeItem('externalLogin')
      const webFormsUrl = config.getInstanceConfig(this.instance).webformsUrl
      const params = {
        eventName: this.event,
      }
      const queryStrings = utils.queryString(params)
      let logoutUrl = '/Main/Logout'

      if (this.backendConfig.defaults.externalAuth) {
        if (this.backendConfig.defaults.externalAuth.logoutUrl) {
          logoutUrl = this.backendConfig.defaults.externalAuth.logoutUrl
          // window.location.href = `${webFormsUrl + logoutUrl}?${queryStrings}`
          window.location.href = logoutUrl
          return
        }
      }
      // this.$router.push(utils.getRouteWithDefaultParams('LoginPage'))
      window.location.href = `${webFormsUrl + logoutUrl}?${queryStrings}`
    },
    login() {
      this.tracking('login')
      userState.unsetUser(this.instance)
      this.$store.commit('instancesState/setToken', null)

      const instanceConfig = config.getInstanceConfig(this.instance)
      let returnPath = this.$route.path
      if (
        this.$route.name === 'AuthPage'
        || this.$route.name === 'NoaccessPage'
      ) {
        returnPath = `/Home/${this.instance}/${this.event}/${this.language}`
      }

      const redirectUri = new URL(window.location.origin + returnPath)
      if (Object.prototype.hasOwnProperty.call(instanceConfig, 'sso')) {
        const params = {
          shortname: this.event,
          language: this.language,
          returnUrl: window.location.origin + this.$route.path+'?',
        }
        if (
          Object.prototype.hasOwnProperty.call(instanceConfig.sso, 'context')
        ) {
          Object.assign(params, { context: instanceConfig.sso.context })
        }

        if (
          Object.prototype.hasOwnProperty.call(instanceConfig.sso, 'provider')
        ) {
          Object.assign(params, { provider: instanceConfig.sso.provider })
        }
        this.$router.push(utils.getRouteWithDefaultParams('LoginPage'))
        return
      }

      const params = {
        returnUrl: redirectUri,
      }
      const queryString = utils.queryString(params)
      const url = `${instanceConfig.webformsUrl}/Home/GetToken?${queryString}`
      localStorage.setItem('tryLogin', '123')
      if (!config.getInstanceConfig(this.instance).identityUrl) {
        utils.redirectOAuthAuthorize(this.$route)
        return
      }

      if (this.externalAuth) {
        if (this.backendConfig.defaults.externalAuth.loginUrl) {
          // window.location.href = `${webFormsUrl + logoutUrl}?${queryStrings}`
          window.location.href = this.backendConfig.defaults.externalAuth.loginUrl
          return
        }
      }
      if (this.$route.name !== 'LoginPage') {
        window.location.href = url
      }
    },
    profile() {
    // TODO: Remove from code: this.customProfileUrl  -->
      if ((this.headerConfig && this.headerConfig.customProfileUrl.customProfileUrl) || this.customProfileUrl) {
        window.location.href = ((this.headerConfig
          && this.headerConfig.customProfileUrl.customProfileUrl)
          || this.customProfileUrl)
        return
      }
      if (!this.menuDisabled) {
        this.tracking('MyProfile')
        this.$router.push(utils.getRouteWithDefaultParams('MyAreaPage'))
      }
    },
    getLanguageIcon(language) {
      return config.getLanguageIcon(language)
    },
    setIsScrolled() {
      const navbar = document.getElementById('vmx-header-nav')
      const sticky = navbar?.offsetTop
      this.isScrolled = window.scrollY > sticky
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
  },
  mounted() {
    if (userState.getUser(this.instance)?.accessToken && this.signalRConnection) {
      this.$accountHub.startSignalRAccount(this.instance, this.event, userState.getUser(this.instance).accessToken, this.signalRConnectionAmount, null, userState.getUser(this.instance).uuid)
    }
    if (this.headerTheme === this.headerThemes.theme2 || this.timeEnabled) {
      this.interval = setInterval(this.eventTime, 1000)
    }
    window.addEventListener('scroll', this.setIsScrolled, { passive: true })
    this.setIsScrolled()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  destroyed() {
    window.removeEventListener('scroll', this.setIsScrolled, { passive: true })
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    $route() {
      this.programHeight = this.$router.currentRoute.name === 'ProgramPage'
        || this.$router.currentRoute.name === 'PosterPage'
      this.showCollapse = false
      this.menuIconVariant = 'collapsed'
    },
    windowWidth(val) {
      if (val >= (this.storedWidth || 1400)) {
        this.collapseNav = true
      }
      if (document.getElementById('navbar-nav__First')?.offsetWidth
        + document.getElementById('navbar-nav__Second')?.offsetWidth > 0) {
        this.storedWidth = document.getElementById('navbar-nav__First').offsetWidth
          + document.getElementById('navbar-nav__Second').offsetWidth
      }
      this.collapseNav = this.storedWidth <= val
    },
  },
}
</script>

<style scoped>

@media (max-width: 1599px) {
  div.navMenu_Icon span {
    font-weight: 500;
    font-size: 1.25rem;
  }
  #menu-icon-collapsed {
    margin: 2px 5px  3px;
    width: 20px;
    height: 20px;
  }
  #menu-icon-not-collapsed {
    margin-right: 5px;
    width: 25px;
    height: 25px;
  }
  button.navbar-toggler div {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    height: 25px;
  }
  span.navMenu__IconTitle {
    margin: 2px 0;
    font-weight: bold;
    font-size: 20px;
  }
  .navMenu__Icon {
    width: 30px;
    color: var(--header-navbar-menu-icon);
  }
}

.vmxHeader-socialMediaIcons {
  margin-left: 1%;
}

.header-text-logo{
  font-size: 40px;
}

.header-info-avatar{
  border-radius: 50%;
  background-color: #063a60;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  color: #fff;
}

</style>
