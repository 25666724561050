<template>
  <div
    id="app"
    class="page"
    :class="[
      this.event + ' ' + $router.currentRoute.name + '_container',
      this.isAppContainer ? 'app_container' : '',
      this.event === 'ers2023' && windowWidth < 1024 ? 'ers_app_container' : '',
      this.isTerminalContainer ? 'terminal_container' : '',
      contentClassData,
      layer,
      isMobileUser ? 'isMobileUser' : ''
    ]"
    :style="`overflow-y: ${showOnFullscreen ? 'scroll' : 'unset'}`"
    ref="app"
  >
    <!-- <div class="vmx-loader-animation pull-down" :style="appLoadingBackground" v-if="!vmxInitialized && appLoaderActive && isAppContainer">
      <div class="vmx-loader-inner-container">
        <img class="pulse" :src="appLoadingLogo" />
      </div>
      <span class="loader" :style="appLoadingLoaderColor" />
    </div> -->
    <template v-if="!isAppContainer && isMobileUser && recommendationActive && showAppDialogue && user">
      <div class="app-recommendation-container">
        <div class="app-recommendation-message" :class="recommendationActive ? 'app-recommendation-message-fade-in' : ''">
          <div class="app-recommendation-headline">
            <h4>{{ recommendationConfig.headline }}</h4>
            <p v-html="recommendationConfig.addAndroidHomescreen && isAndroidUser ? recommendationConfig.subtitleAndroid : recommendationConfig.subtitle"  />
          </div>
          <template v-if="isIosUser">
            <div class="app-recommendation-store">
              <img :src="recommendationConfig.appThumbnail" />
              <a
                id="store-link"
                class="btn btn-primary"
                :href="recommendationConfig.appleStoreLink"
              >
                {{ recommendationConfig.appleStoreText }}
              </a>
            </div>
          </template>
          <template v-if="isAndroidUser && !recommendationConfig.addAndroidHomescreen">
            <div class="app-recommendation-store">
              <img :src="recommendationConfig.appThumbnail" />
              <a
                id="store-link"
                class="btn btn-primary"
                :href="recommendationConfig.playStoreLink"
              >{{ recommendationConfig.playStoreText }}
              </a>
            </div>
          </template>

          <div class="app-recommendation-browser">
            <img :src="recommendationConfig.browserThumbnail" />
            <button @click.prevent="hideAppDialogue" class="btn btn-secondary">{{ recommendationConfig.browserText }}</button>
          </div>
        </div>
      </div>
    </template>
    <template v-if="maintenance">
      <maintenance />
    </template>
    <template v-else>
      <MobileAdBannerVue
        v-if="showMobileTopBanner && showMobileBanner"
        class="top-mobile_banner"
        :carousel-props="mobileBannerTopContent"
      />
      <div
        id="page"
        class="page-content"
        :class="[
          disableHeaderForPage && 'disabledHeader',
          `${$router.currentRoute.name !== 'PagSearchPage' ? $router.currentRoute.name + ' ' + mobileBannerTop : '' + ' ' + mobileBannerTop}`,
        ]"
      >
        <template v-if="init && initialized">
          <template v-if="newVMXHeader">
            <v-m-x-header-v2
              :key="componentKeyHeader"
              :hidden="preview || disableHeader"
              :class="networkingActiveClass"
              v-show="showMobileBanner"
            />
          </template>
          <template v-else>
            <v-m-x-header
              :hidden="preview || disableHeader"
              :class="networkingActiveClass"
            />
          </template>
          <ticker
            v-if="!preview"
            :hidden="preview || hideTicker || disableHeader"
            :class="networkingActiveClass"
          />
          <networking-widget
            @networkingPaddingOff="networkingWidgetPadding = false"
            @networkingActive="addNetworkingPadding"
            :hidden="preview"
            v-if="!preview && user && user.hasTicket && networkingRouteCheck"
          />

          <networking-widget-v2
            :hidden="preview"
            v-if="!preview && user"
          />
          <advertisement-popup
            v-if="!preview && event && user && !splashScreenActive"
          />
          <tv-widget />
          <freshworks v-if="!preview" />
          <data-transfer-modal
            :url="url"
            :show="dataTransferModal"
            @close="dataTransferModal = false"
          />
          <template
            v-if="
              FreshChatAllPages &&
              FreshChatAllPagesConfig &&
              FreshChatAllPagesConfig.freshChatToken &&
              currentRoute !== 'AssemblyPage'
            "
          >
            <fresh-chat
              :fresh-chat-token="FreshChatAllPagesConfig.freshChatToken"
              :enable-chat="FreshChatAllPagesConfig.enableChat"
            />
          </template>
        </template>
        <!-- <keep-alive
          :exclude="excludedPage"
        >
          <router-view
            :key="excludedPage.includes($route.name) ? componentKey : $route.fullPath"
            :class="networkingActiveClass"
            @rerender="forceRerender"
          />
        </keep-alive> -->
        <router-view
          :key="componentKey"
          :class="networkingActiveClass"
          @rerender="forceRerender"
        />
      </div>
      <div
        class="build-version"
        v-if="version && init && initialized"
      >
        <span>{{ version.Version }} | {{ version.BuildDateTimeHuman }}</span>
      </div>
      <template v-if="superUserToggle && showOnFullscreen">
        <div class="admin-options-container">
          <button
            @click="toggleAdminOption"
            class="btn btn-primary admin-options-toggle"
          >
            Admin Panel
          </button>
          <template v-if="adminOptionsVisible">
            <div class="content-toggle-container">
              <div class="content-toggle">
                <button
                  @click.prevent="contentSwitch"
                  class="content-toggle__button"
                  :class="superUser ? 'active' : null"
                >
                  <template v-if="superUser">
                    <span>Content <i class="fas fa-lock" /></span>
                  </template>
                  <template v-else>
                    <span>Content <i class="fas fa-lock-open" /></span>
                  </template>
                </button>
              </div>
              <div
                class="content-toggle"
                v-if="version && init && initialized && !version.Version.includes('develop')"
              >
                <button
                  @click.prevent="buildSwitchDev"
                  class="content-toggle__button"
                >
                  <span>Dev <i class="fas fa-laptop-code" /></span>
                </button>
              </div>
              <div
                class="content-toggle"
                v-if="version && init && initialized && !version.Version.includes('master')"
              >
                <button
                  @click.prevent="buildSwitchProduction"
                  class="content-toggle__button"
                  :class="!isVMXDev ? 'active' : null"
                >
                  <span>Live <i class="fas fa-laptop" /></span>
                </button>
              </div>
              <div class="content-toggle">
                <button
                  @click="toggleAppContainer"
                  class="content-toggle__button"
                  :class="isAppContainer ? 'active' : null"
                >
                  <span>App <i class="fas" :class="isAppContainer ? 'fa-eye-slash' : 'fa-eye'"/></span>
                </button>
              </div>
              <div class="content-toggle">
                <button
                  @click="toggleTerminalContainer"
                  class="content-toggle__button"
                  :class="isTerminalContainer ? 'active' : null"
                >
                  <span>Terminal <i class="fas" :class="isTerminalContainer ? 'fa-eye-slash' : 'fa-eye'"/></span>
                </button>
              </div>
            </div>
          </template>
        </div>
      </template>
      <div
        v-if="terminal && terminalFooterImage"
        id="terminal_container-footer"
      >
        <!-- <p class="terminal_container-footer-text">
          This interactive touch screen is sponsored by
        </p> -->
        <img
          class="terminal_container-footer-logo"
          :src="terminalFooterImage"
        />
      </div>
      <template v-if="newVMXFooter && !terminal && !hideFooterOnApp">
        <v-m-x-footer-v2
          v-if="!preview && !disableFooter && init && initialized && showOnFullscreen"
          :hidden="preview"
          class="page-footer"
          :style="{
            'margin-bottom': showDesktopBottomBanner ? `${desktopBannerHeight + 50}px` : (showMobileBottomBanner ? `${mobileBannerHeight + 50}px` : '')
          }"
          id="page-footer"
        />
      </template>
      <template v-else-if="!terminal && !hideFooterOnApp">
        <div
          v-if="!preview && !disableFooter && init && initialized && showOnFullscreen"
          class="page-footer"
          id="page-footer"
          :style="{
            'border-left': session ? `solid 4px ${session.Color}` : '',
            'margin-bottom': showDesktopBottomBanner ? `${desktopBannerHeight + 50}px` : (showMobileBottomBanner ? `${mobileBannerHeight + 50}px` : '')
          }"
          :hidden="preview"
        >
          <m-footer v-bind="footerProps" :class="networkingActiveFooterClass" />
        </div>
      </template>

      <MobileAdBannerVue
        v-if="showMobileBottomBanner && showMobileBanner"
        class="bottom-mobile_banner"
        :style="!!mobileBannerHeight ? `height: ${mobileBannerHeight}px;`: ''"
        :carousel-props="mobileBannerBottomContent"
      />

      <MobileAdBannerVue
        v-if="showDesktopBottomBanner"
        class="bottom-mobile_banner"
        :style="!!desktopBannerHeight ? `height: ${desktopBannerHeight}px;` : ''"
        :carousel-props="desktopBannerBottomContent"
      />
      <div>
        <cookie-notification v-if="init && initialized" :hidden="preview" />
      </div>
      <notification-toast v-if="init && initialized" :hidden="preview" />
      <a id="scrollTopButton" class="back-to-top" @click="scrollToTop()">
        <font-awesome-icon :icon="['fas', 'chevron-up']" />
      </a>

      <div
        v-if="init && initialized && boothGuruActive"
        id="bg-popup"
        style="position: fixed; z-index: 2147483647"
      />
      <!-- <booth-guru-dialog
        v-if="initialized && showBoothGuruModal && boothGuruActive"
        :hidden="preview"
        @close="showBoothGuruModal = false"
      /> -->
      <b-toast
        id="my-toast-cache-refresh"
        variant="warning"
        solid
        :no-auto-hide="true"
        :no-close-button="true"
       
        toaster="b-toaster-bottom-right"
        >
        <template #toast-title>
          <div>
            <strong>{{swDownload ? 'Downloading!' :'Data cached for offline use!'}}</strong>


          </div>
        </template>
        <div v-if="swDownload" class="loader-container">
          <b-spinner class="loader" />
          <span class="loader-text">{{
            $te("VMX.loading_text_pag")
              ? $t("VMX.loading_text_pag")
              : "Loading content..."
          }}</span>
          </div>
          <div v-else>
            <b-button @click="refresh()">Refresh</b-button>
            </div>
      </b-toast>
      <b-alert
        class="position-fixed fixed-bottom m-0 rounded-0"
        style="z-index: 2000;"
        :variant="this.condition === 'online' ? 'success' : 'danger'"
        :show="this.condition === 'online' ? dismissCountDown : true"
        dismissible
        @dismissed="dismissCountDown === 0"
        @dismiss-count-down="countDownChanged"
      >
        {{ this.condition === 'online' ? 'You are online' : 'Please note that some features and functions are currently not supported in offline mode.' }}
        <b-progress
          v-if="this.condition === 'online'"
          :variant="this.condition === 'online' ? 'success' : 'danger'"
          :max="dismissSecs"
          :value="dismissCountDown"
          height="4px"
        />
      </b-alert>
      <modal :show="iframeModal" @close="iframeModal = false">
        <iframe
          allowfullscreen
          :src="iframeUrl"
          style="border: 0; height: 800px; width: 100%"
          loading="lazy"
          title="#footerModalund"
        />
      </modal>
      <modal :show="iframeModal" @close="iframeModal = false">
        <iframe
          allowfullscreen
          :src="iframeUrl"
          style="border: 0; height: 800px; width: 100%"
          loading="lazy"
          title="#footerModalund"
        />
      </modal>
      <modal
        :show="exhibitorModal"
        @close="exhibitorModal = false"
        class="floorplanModal"
        id="floorplanModal"
      >
        <div class="floorplanModal-container">
          <div class="floorplanModal-container-row">
            <img
              class="floorplanModal-item image"
              :src="exhibitor ? exhibitor.LogoPath : ''"
            />
            <h1 class="floorplanModal-item headline background">
              {{ exhibitor ? exhibitor.Name : "" }}
            </h1>
            <p class="floorplanModal-item text background">
              <span v-if="exhibitor && exhibitor.BoothNumbers">{{ exhibitor.BoothNumbers.length > 1 ? 'Booths' : 'Booth'}}</span>
              {{
                exhibitor && exhibitor.BoothNumbers
                  ?  [...exhibitor.BoothNumbers].sort((a, b) => a - b).join(', ')
                  : ""
              }}
            </p>
            <p class="floorplanModal-item text background">
              {{
                exhibitor && exhibitor.SponsorType ? exhibitor.SponsorType : ""
              }}
            </p>
            <button
              @click="openFloorplanCompany()"
              class="floorplanModalWrapperContent"
            >
              <span>{{ $t("VMX.show_more_button") }}</span>
            </button>
          </div>
        </div>
      </modal>
      <modal
        :show="showMobileCarousel"
        @close="closeIntroMobileCarousel()"
        :show-close-button="showMobileCarouselCloseBtn"
        :disable-window-width="true"
        :show-close-outside-button="false"
        class="intro-mobile-modal"
      >
        <Carousel
          v-bind="mobileCarouselData"
          style="border-radius: 20px"
        />
      </modal>
      <modal
        :show="showEvaluationModal"
        @close="showEvaluationModal = false"
        class="evaluation-box__modal"
      >
        <evaluation-component :session="session" />
      </modal>
      <modal
        :disable-backdrop-close="false"
        :show-close-outside-button="false"
        :disable-window-width="true"
        :show="showLogoutUserModal"
        @close="showLogoutUserModal = false"
        class="logout-user__modal"
      >
          <div class="logout-modal__container">
            <h1
              v-html="$t('VMX.logout_user-condition-title')"
            />
            <div class="logout_user_content_container">
              <span 
                v-html="$t('VMX.logout_user-condition-txt')"
              />
              <div class="logout_user_action_container">
                <button @click="emitContinue()"> {{ $t("VMX.continue_user-txt") }} </button>

                <button @click="emitLogout()"> {{ $t("VMX.logout_user-txt") }} </button>
              </div>
            </div>
          </div>

      </modal>
      <user-information-modal v-if="userInformationModalShow" />
      <hcp-dialog
        v-if="hcpInitialized && user && hcpModalActive"
        :show="showHCPModal"
        @close="handleCloseHCPForm"
      />
      <access-denied
        v-if="onAccessDeniedDialogClose"
        @close="onAccessDeniedDialogClose = false"
      />
      <rating-reminder
        v-if="ratingReminderInitialized"
        :show="showRatingReminderModal"
        @close="handleCloseRatingReminder"
      />
      <div
        v-if="terminal"
        id="video-container"
        style="background:f4f4f4;top:0px !important"
        class="terminalvideo-container"
      >
        <img
          style="    width: 100%;
    height: auto;
    display: block;
    position: relative;
    left: 0px;
    top: 135px;"
          src="https://ean-distribution.m-anage.com/from.storage?filecore=1fZCGOZzl2fOdKfpm68tWg"
        />
        -- <video
          width="400"
          class="terminal-video-player"
          muted="muted"
          loop
        >
          <source
            src="https://ean-distribution.m-anage.com/from.storage?filecore=4ocbdXS3OEwNiH97toAVjw"
            type="video/mp4"
          />
          Your browser does not support HTML5 video.
        </video>
        <img
          style="width: 100%;
    height: auto;
    display: block;
    position: absolute;
    left: 0px;
    bottom: 95px;
      "
          src="https://ean-distribution.m-anage.com/from.storage?filecore=iG4ij3Wo9NNbYVC91SLM1Q"
        /> 
      </div>
    </template>
  </div>
</template>

<script>
import './assets/scss/app.scss'
import './vendor/m-footer/m-footer.css'
import EventBus from '~/states/event-bus'

import { mapActions, mapMutations, mapGetters } from 'vuex'
import NotificationToast from 'm-anage.com.vmx-components/src/NotificationToast/index.vue'
import utils from '~/utilities/utils'
import virtualMeetingAPI from '~/api/virtualMeeting'

import trackingApi from '~/api/tracking'

import instancesStateMixins from '~/mixins/instanceStateMixins'
import dialogMixins from '~/mixins/dialogMixins'
import AdvertisementPopup from '~/components/AdvertisementPopup.vue'
import tvWidget from '~/components/sidebars/tvWidget.vue'
import Freshworks from '~/components/Freshworks.vue'
import externalTrackingMixin from '~/mixins/externalTrackingMixins'
import FreshChatAllPagesMixins from '~/mixins/FreshChatAllPagesMixins'
import modal from 'm-anage.com.vmx-components/src/Modal'
import VMXHeaderV2 from '~/components/header/index.vue'
import AccessDenied from 'm-anage.com.vmx-components/src/Dialogs/AccesDeniedDialog'
import VMXFooterV2 from '~/components/footer/index.vue'
import appContainerMixins from 'm-anage.com.vmx-components/src/mixins/appContainerMixins'
import userInformationModal from 'm-anage.com.vmx-components/src/UserCard/UserInformationModal'
import Carousel from 'm-anage.com.vmx-components/src/Carousel/index.vue'
import EvaluationComponent from '~/components/industryLandingPage/EvaluationComponent.vue'
import VMXHeader from './components/VMXHeader.vue'
import MFooter from './vendor/m-footer/m-footer.common'
import CookieNotification from './components/CookieNotification.vue'
import Tracking from './utilities/tracking'
import userState from './states/user'
import Ticker from './components/ticker.vue'
import config from './config'
import NetworkingWidget from './components/networkingWidget.vue'
import NetworkingWidgetV2 from './components/networkingWidgetV2.vue'
import MobileAdBannerVue from './components/MobileAdBanner.vue'
import maintenance from '~/pages/maintenance'
import headerMixins from './mixins/headerMixins'
import appLoadingData from '../public/appLoadingData'

export default {
  mixins: [
    instancesStateMixins,
    dialogMixins,
    externalTrackingMixin,
    FreshChatAllPagesMixins,
    appContainerMixins,
    headerMixins,
  ],
  data() {
    return {
      showLogoutUserModal: false,
      userInformationModalShow: false,
      documentReadyState: null,
      showEvaluationModal: false,
      appLoaderData: appLoadingData,
      session: null,
      contentClassData: '',
      excludedPage: ['MyAreaPage', 'Livepage'],
      componentKey: 0,
      componentKeyHeader: 100,
      initialized: true,
      disableFooter: false,
      version: null,
      currentRoute: '',
      onAccessDeniedDialogClose: false,
      networkingActive: false,
      networkingWidgetPadding: true,
      splashScreenActive: false,
      iframeUrl: '',
      iframeModal: false,
      showMobileBanner: true,
      exhibitorModal: false,
      isAppContainer: false,
      vmxInitialized: false,
      isTerminalContainer: false,
      time: null,
      showMobileCarousel: false,
      windowWidth: window.innerWidth,
      showMobileCarouselCloseBtn: false,
      exhibitor: null,
      gTagScript: null,
      condition: 'online',
      dismissSecs: 10,
      dismissCountDown: 0,
      showOnFullscreen: true,
      isMobileUser: false,
      isAndroidUser: false,
      isIosUser: false,
      showAppDialogue: true,
      adminOptionsVisible: false,
      isVMXDev: false,
      loaderImageUrl: 'https://distribute.m-anage.com/from.storage?filecore=EHWv7agXAJZ-veNT29GB3Q',
      // showBoothGuruModal: false,
    }
  },
  components: {
    NetworkingWidgetV2,
    VMXHeader,
    Ticker,
    MFooter,
    CookieNotification,
    NotificationToast,
    NetworkingWidget,
    AdvertisementPopup,
    tvWidget,
    Freshworks,
    modal,
    MobileAdBannerVue,
    Carousel,
    VMXHeaderV2,
    VMXFooterV2,
    AccessDenied,
    maintenance,
    EvaluationComponent,
    userInformationModal,
  },
  provide: {
    TrackingUtil: Tracking,
  },
  computed: {
    ...mapGetters({
      init: 'instancesState/getInitializedHead',
      swDownload: 'instancesState/getSWDownload',
      maintenance: 'instancesState/getMaintenance',
      /* getPushnotifications: 'pushnotifications/pushnotification', */
    }),
    layer() {
      if (localStorage.getItem('isVMX') === 'true') return 'vmx-context'
      if (localStorage.getItem('isApp') === 'true') return 'app-context'
      if (localStorage.getItem('isTerminal') === 'true') { return 'terminal-context' }
      return ''
    },
    terminalFooterImage() {
      return this.configForKey('app_config').config[0].defaults.terminal.terminalFooterImage || ''
    },
    footerProps() {
      return {
        apiBaseUrl: `${this.$store.getters['instancesState/getApiBaseUrl']}/`,
        language: this.language,
        shortname: this.event,
        context: 'Event',
      }
    },
    hideTicker() {
      return (
        this.backendConfig.defaults?.hideTicker
        && this.currentRoute === 'HomePage'
      )
    },
    // appLoaderActive() {
    //   let isActive = false
    //   if (this.event in this.appLoaderData) {
    //     isActive = this.appLoaderData[`${this.event}`][0].appLoaderActive
    //     return isActive
    //   }
    //   return isActive
    // },
    appLoadingLogo() {
      let url = null
      if (this.event in this.appLoaderData) {
        url = this.appLoaderData[`${this.event}`][0].appLoadingLogo
        return url
      }
      url = 'https://distribute.m-anage.com/from.storage?filecore=OqIgpgSCikJwvHSB8O1nwQ'
      return url
    },
    // appLoadingBackground() {
    //   let styles = null
    //   if (this.event in this.appLoaderData) {
    //     styles = `background-color: ${this.appLoaderData[`${this.event}`][0].backgroundColor};`
    //     return styles
    //   }
    //   styles = 'background-color: #fff'
    //   return styles
    // },
    appLoadingLoaderColor() {
      let styles = null
      if (this.event in this.appLoaderData) {
        styles = `border: 1px solid ${this.appLoaderData[`${this.event}`][0].loaderColor}; background: ${this.appLoaderData[`${this.event}`][0].loaderColor} `
        return styles
      }
      styles = 'border: 1px solid #acacac; background: var(--primary)'
      return styles
    },
    mobileBannerTop() {
      const mobileBannerTop = this.backendConfig?.defaults?.mobileBannerTop
      if (this.isAppContainer && mobileBannerTop) {
        return 'mobile_banners_top'
      }

      return ''
    },
    showMobileTopBanner() {
      const mobileBannerTop = this.backendConfig?.defaults?.mobileBannerTop
      if (this.isAppContainer && mobileBannerTop) {
        return true
      }
      return false
    },
    showMobileBottomBanner() {
      const mobileBannerBottom = this.backendConfig?.defaults?.mobileBannerBottom
      if (this.isAppContainer && mobileBannerBottom) {
        return true
      }
      return false
    },
    showDesktopBottomBanner() {
      const showDesktopBottomBanner = this.backendConfig?.defaults?.showDesktopBottomBanner
      if (!this.isAppContainer && showDesktopBottomBanner) {
        return true
      }
      return false
    },
    mobileBannerHeight() {
      return "mobileBannerHeight" in this.backendConfig.defaults ? this.backendConfig.defaults.mobileBannerHeight : 75
    },
    desktopBannerHeight() {
      return "desktopBannerHeight" in this.backendConfig.defaults ? this.backendConfig.defaults.desktopBannerHeight : 100
    },
    terminal() {
      return localStorage.getItem('isTerminal')
    },
    mobileCarouselData() {
      return this.backendConfig?.defaults?.mobileCarousel?.Carousel
    },
    useCompanyAccountsV2() {
      const config = this.$store.getters['config/configForKey']('app_config')?.configV2?.defaultV2
      if (config && 'useCompanyAccountsV2' in config) {
        return config.useCompanyAccountsV2
      }
      return false
    },
    mobileBannerBottomContent() {
      return this.backendConfig?.defaults?.mobileBannerBottom
    },
    desktopBannerBottomContent() {
      return this.backendConfig?.defaults?.desktopBannerBottom
    },
    mobileBannerTopContent() {
      return this.backendConfig?.defaults?.mobileBannerTop
    },
    moreSpaceConfig() {
      return this.backendConfig.defaults?.disableVMXFooter
    },
    user() {
      return userState.getUser(this.instance)
    },
    hcpModalActive() {
      return this.configForKey('app_config').config[0].defaults.showHCPModal
    },
    gTagConfig() {
      return this.configForKey('app_config')?.config[0]?.defaults.gtagConfig
    },
    networkingActiveClass() {
      if (this.networkingWidgetPadding) {
        return this.networkingActive
          && document.getElementById(
            'networking-widget__sidebar',
            // eslint-disable-next-line max-len
          )?.childNodes.length > 0
          && !this.preview
          && this.currentRoute !== 'UserFormPage'
          && this.currentRoute !== 'NoaccessPage'
          ? 'networking-padding-right'
          : null
      }
      return null
    },
    networkingActiveFooterClass() {
      if (this.networkingWidgetPadding) {
        return this.networkingActive
          && document.getElementById(
            'networking-widget__sidebar',
            // eslint-disable-next-line max-len
          )?.childNodes.length > 0
          && !this.preview
          && this.currentRoute !== 'UserFormPage'
          && this.currentRoute !== 'NoaccessPage'
          ? 'networking-padding-right networking-padding-bottom'
          : null
      }
      return null
    },
    showNetworkingOnHomepage() {
      return (
        this.backendConfig.defaults?.NetworkingWidget
          ?.showNetworkingOnHomepage || false
      )
    },
    NetworkingOnPages() {
      return (
        this.backendConfig.defaults?.NetworkingWidget?.NetworkingOnPages || []
      )
    },
    FreshChatAllPages() {
      return (
        this.$route.name !== 'IndustryLandingPageProfile'
        && this.$route.name !== 'ProfilePage'
      )
    },
    networkingRouteCheck() {
      return (
        this.currentRoute !== 'UserFormPage'
        && this.currentRoute !== 'NoaccessPage'
        && this.currentRoute !== 'AssemblyPage'
        && this.currentRoute !== 'SurveyPage'
        && this.currentRoute !== 'MaintenancePage'
      )
    },
    disableHeaderForPage() {
      const pages = this.backendConfig?.defaults?.disableHeaderForPage || ['SubmissionPage']
      return pages.includes(this.$route.name)
    },
    newVMXHeader() {
      return !!this.backendConfig.defaults?.newVMXHeader
    },
    newVMXFooter() {
      return !!this.backendConfig?.defaults?.newVMXFooter
    },
    hideFooterOnApp() {
      if (this.isAppContainer && this.backendConfig?.defaults?.hideFooterInApp) {
        return this.backendConfig.defaults.hideFooterInApp
      }
      return false
    },
    vmxDevUrl() {
      const defaults = this.backendConfig?.defaults
      if ('vmxDevUrl' in defaults) {
        return defaults.vmxDevUrl
      }
      return null
    },
    vmxProductionUrl() {
      const defaults = this.backendConfig?.defaults
      if ('vmxProductionUrl' in defaults) {
        return defaults.vmxProductionUrl
      }
      return null
    },
    recommendationActive() {
      return !!this.backendConfig?.defaults?.recommendationActive
    },
    recommendationConfig() {
      return this.backendConfig.defaults?.recommendation
    },
  },
  created() {
    window.addEventListener('isAppContainer', this.setIsAppContainer)
    window.addEventListener('isTerminalContainer', this.setTerminalContainer)
    window.addEventListener('resize', this.onResize)

    if (localStorage.getItem('isTerminal') === 'true') { this.setTerminalContainer() }

    if (localStorage.getItem('isApp') === 'true') this.setAppContainer()

    if (/Android/i.test(navigator.userAgent)) {
      this.isMobileUser = true
      this.isAndroidUser = true
    }
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      this.isMobileUser = true
      this.isIosUser = true
    }
    if (localStorage.getItem('appRecommendationSeen') === 'true') {
      this.showAppDialogue = false
    }
  },
  /* updated() {
    this.checkNewNotifications()
  }, */
  async mounted() {
    /* await this.checkNewNotifications() */
    if(this.event === "ers2023") {
      this.$loadScript('https://prod-druid-apc.azureedge.net/v2/druid_webchat_v2_load.js?botId=f7aec904-72b2-4f13-591a-08db98305aea&baseUrl=https%3A%2F%2Fdruidapi.druidplatform.com')
    }

    if((this.event === "ean2023" || this.event === "ean2024") && !window.location.search.includes('preview=true')) {
      this.$loadScript('https://d2t0unnjxes6lt.cloudfront.net/data/ean/data/assets/js/sw.js')
      this.$loadScript('https://d2t0unnjxes6lt.cloudfront.net/data/ean/data/assets/js/e.js',{
        'id':'_etLoader',
        'data-block-cookies':'true',
        'data-respect-dnt':'true',
        'data-product-identifier':'signalize',
        'charset':'UTF-8',
        'data-secure-code':'OXExjV'
      })
    }
    window.addEventListener('scroll', this.handleScroll)
    this.contentClass()
    this.version = await virtualMeetingAPI.getBuildVersion()
    EventBus.$on('rerender', () => {
      this.forceRerender()
    })
    this.$root.$on('rerender', () => {
      this.forceRerender()
    })
    this.$root.$on('openUserInformationModal', () => {
      this.userInformationModalShow = true
    })
    this.showLogoutUserModal = sessionStorage.getItem('open-signalr-logout-modal') === 'true' && this.user

    this.$root.$on('open-signalr-logout-modal', () => {
      this.showLogoutUserModal = true
      sessionStorage.setItem('open-signalr-logout-modal', true)
    })

    this.$root.$on('close-signalr-logout-modal', () => {
      sessionStorage.removeItem('open-signalr-logout-modal')
      this.showLogoutUserModal = false
    })

    EventBus.$on('noaccess', (params) => {
      this.onAccessDeniedDialogClose = params
    })
    this.$root.$on('externalTrackingDataTransfer', (url) => {
      this.url = url
      this.dataTransferModal = true
    })
    // if (instancesStateMixins.computed.boothGuruActive) {
    //   dialogMixins.methods.callBoothGuruDialog()
    //   window.addEventListener('boothGuru', this.receiveMessage)
    // }
    window.openFooterModal = (url) => {
      this.iframeUrl = url
      this.iframeModal = true
    }

    window.openFooterModal = (url) => {
      this.iframeUrl = url
      this.iframeModal = true
    }

    window.openEvaluationModal = (session) => {
      this.session = session
      this.showEvaluationModal = true
    }
    // hide top and bottom banners when image map on full screen
    if (this.event === 'ewma2023') {
      window.onpopstate = () => {
        if (this.showMobileBanner === false) {
          this.showMobileBanner = true
        }
      }
      window.hideMobileBanner = () => {
        this.showMobileBanner = false
      }
      window.showMobileBanner = () => {
        this.showMobileBanner = true
      }
    }

    window.hideOnFullscreen = () => {
      this.showOnFullscreen = false
    }

    window.showOnFullscreen = () => {
      this.showOnFullscreen = true

    }
    this.removeMobileCarouselShown()
    dialogMixins.methods.callHCPDialog()
    window.addEventListener('hcpDialog', this.receiveMessage)
    dialogMixins.methods.callRatingReminder()
    window.addEventListener('ratingReminder', this.receiveMessage)
    window.addEventListener('routerEvent', this.receiveMessage)
    window.addEventListener('scrollToTop', this.receiveMessage)

    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);

    window.tracking = async (
      sourceContentAction,
      entityId,
      sourceContext,
      target,
      link,
      openModal = true,
    ) => {
      if( (document.getElementsByClassName("terminal_container")[0] && link.includes('poster')) || (document.getElementsByClassName("terminal_container")[0] && link.includes('Scientific_Theatre'))){
        return;
      }
      localStorage.removeItem('wentBack')
      const fullscreen = document.getElementsByClassName('fullscreen')
      if ((this.isAppContainer && !fullscreen.length) || (!fullscreen.length && window.location.href.includes("ers2023") && window.innerWidth < 1024)) return
      await this.windowActionTracking(
        sourceContentAction,
        entityId,
        sourceContext,
      )
      if (utils.isUrl(link)) {

        if (sourceContentAction === 'vmx-imageMap-link' && openModal) {
          this.exhibitor = await this[this.useCompanyAccountsV2 ? 'getExhibitorProfileByIdV2' : 'getExhibitorProfileById'](
            link.substring(link.lastIndexOf('/') + 1),
          )

          this.exhibitorModal = true
        } else {
          window.open(link, target)
        }
      } else if (utils.isObject(link)) {
        if (sourceContentAction === 'vmx-imageMap-link' && openModal) {
          this.exhibitor = await this[this.useCompanyAccountsV2 ? 'getExhibitorProfileByIdV2' : 'getExhibitorProfileById'](
            link.substring(link.lastIndexOf('/') + 1),
          )
          this.exhibitorModal = true
        } else {
          this.$router.push(
            utils.getRouteWithDefaultParams(link.name, link.params),
          )
        }
      } else if (
        sourceContentAction === 'vmx-imageMap-link'
        && openModal
        && link
      ) {
        this.exhibitor = await this[this.useCompanyAccountsV2 ? 'getExhibitorProfileByIdV2' : 'getExhibitorProfileById'](
          link.substring(link.lastIndexOf('/') + 1),
        )
        this.exhibitorModal = true

        if (localStorage.getItem('isTerminal') === 'true') {
          setTimeout(() => {
            const parentEl = document.querySelector('#imagemap1-container')
            const childEl = document.querySelector('#floorplanModal')
            if (!parentEl?.contains(childEl) && childEl != null) {
              const source = document.getElementById('imagemap1-container')
              const child = document.getElementById('floorplanModal')
              if (source) source.appendChild(child)
            }
          }, 500)
        }
      } else {
        window.open(link, target)
      }
    };

    this.setUrConfig({
      oauthUrl: config.getInstanceConfig(this.instance)?.oauthUrl,
      identityUrl: this.identityUrl,
      apiUrl: this.apiUrl,
      clientId: config.getInstanceConfig(this.instance)?.oauth2.clientId,
      instance: this.instance,
      language: this.language,
      eventShort: this.event,
      context: 'Event',
      returnUrl: this.returnUrl,
    })
    this.observer = new MutationObserver((mutations) => {
      for (const m of mutations) {
        const newValue = m.target.getAttribute(m.attributeName)
        this.$nextTick(() => {
          this.onClassChange(newValue, m.oldValue)
        })
      }
    })
    this.observer.observe(this.$refs.app, {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ['class'],
    })
    window.openLink = (url) => {
      this.openLink(url)
    }
    if (this.version.Version.includes('develop')) {
      this.isVMXDev = true
    }
    setTimeout(() => {
      this.vmxInitialized = true
    }, 2000)
     
    // document.onreadystatechange = () => {
    //   if (document.readyState === 'complete') {
    //     setTimeout(() => {
    //       this.documentReadyState = document.readyState
    //     }, 2000)
    //   }
    // }
  },
  watch: {
    // documentReadyState(val) {
    //   console.log(val)
    //   if (val === 'complete') {

    //       this.vmxInitialized = true

    //   }
    // },
    gTagConfig() {
      if (this.gTagConfig?.isActive) {
        this.appendGtag()
      }
    },
    swDownload(val) {
     // if(val) //this.showToast()
    },
    init() {
      const appContainer = document.getElementById('app')
      appContainer.classList.remove(...appContainer.classList)
      appContainer.classList.add(
        this.$store.getters['instancesState/getEvent'],
      )
      appContainer.classList.add(this.$route.name)
    },
    firstError() {
      if (
        this.firstError?.response
        && this.firstError.response.status === 401
      ) {
        let tries = localStorage.getItem('VMXreload')
          ? parseInt(localStorage.getItem('VMXreload'), 10)
          : 0
        tries += 1
        localStorage.setItem('VMXreload', tries)
        userState.unsetUser(this.instance)
        this.$store.commit('instancesState/setToken', null)

        const reloadRoute = {
          name: this.$router.history.pending.name,
          query: this.$router.history.pending.query,
          params: this.$router.history.pending.params,
        }
        if (
          Object.prototype.hasOwnProperty.call(
            reloadRoute.query,
            'access_token',
          )
        ) {
          delete reloadRoute.query.access_token
        }
        this.clearErrors()
        if (tries <= 3) {
          this.$router.replace(reloadRoute)
        } else {
          localStorage.removeItem('VMXreload')
        }
      }
    },
    networkingActive(val) {
      this.showNetworkingWidgetForPages(val)
    },
    $route() {
      if (this.backendConfig?.defaults?.favicon) {
        utils.changeFavicon(this.backendConfig.defaults.favicon)
      }
      this.scrollToTop()
      if (
        this.$router.currentRoute.name === 'HoldingPage'
        || (!this.backendConfig.OnboardingV2
          && this.$router.currentRoute.name === 'AuthPage')
      ) {
        this.initialized = false
      } else if (this.$router.currentRoute.name !== '') {
        this.initialized = true
      }

      // Todo remove after a year

      if (
        this.$router.currentRoute.name === 'IndustryLandingPage'
        && !!this.$router.currentRoute.query.id
      ) {
        this.forceRerender()
      }

      if (
        this.$router.currentRoute.name === 'ProfilePage'
        && !!this.$router.currentRoute.params.companyAccountId
      ) {
        this.forceRerender()
      }

      this.currentRoute = this.$router.currentRoute.name
      this.showNetworkingWidgetForPages(this.networkingActive)
      // eslint-disable-next-line max-len
      this.disableFooter = config.getMoreSpaceConfig(
        this.$router.currentRoute.name,
        this.moreSpaceConfig,
      )
      this.setMobileCarousel()
    },
  },
  beforeDestroy() {
    window.removeEventListener('ratingReminder', this.receiveMessage)
    window.removeEventListener('routerEvent', this.receiveMessage)
    // if (instancesStateMixins.computed.boothGuruActive) {
    //   window.removeEventListener('boothGuru', this.receiveMessage)
    // }
    this.observer.disconnect()
    localStorage.removeItem('nextPage')
  },
  methods: {
    ...mapMutations({
      setUrConfig: 'ModuleConfig/setUrlConfigs',
      setIsAppContainer: 'instancesState/setIsAppContainer',
      setIsTerminalContainer: 'instancesState/setIsTerminalContainer',
    }),
    emitContinue() {
      this.$root.$emit('continue-user-device')
    },
    emitLogout() {
      this.$root.$emit('logout-user-device')
    },
    /* async checkNewNotifications() {
      if (this.vmxInitialized) {
        const latestNotifications = JSON.stringify(this.getPushnotifications)
        const storedNotifications = JSON.parse(localStorage.getItem('notificationList'))
        const notificationMenuItem = document.getElementById('notification-app-menu')
        console.log(JSON.stringify(this.getPushnotifications))
        if (latestNotifications.length === storedNotifications.length) {
          alert('equal')
          notificationMenuItem.classList.remove('dot')
        } else {
          alert('not-equal')
          notificationMenuItem.classList.add('dot')
        }
      }
    }, */
    showToast() {
        this.$bvToast.show("my-toast-cache-refresh")
    },
    countDownChanged(dismissCountDown) {
      if(this.condition === 'online') {
        this.dismissCountDown = dismissCountDown
      }
    },
    refresh() {
      window.location.reload()
    },
    onResize(e) {
      this.windowWidth = window.innerWidth
    },
    updateOnlineStatus(event) {
       this.condition = navigator.onLine ? "online" : "offline";
       this.dismissCountDown = this.dismissSecs
    },
    contentClass() {
      if (localStorage.getItem('content')) {
        this.contentClassData = `is-${localStorage.getItem('content')}-content`
        return
      }
      this.contentClassData = ''
    },
    closeIntroMobileCarousel() {
      const mobileCarousel = this.backendConfig?.defaults?.mobileCarousel
      this.showMobileCarousel = false
      localStorage.setItem(
        'mobileCarouselShown',
        JSON.stringify({
          shown: true,
          showOnReload: mobileCarousel.showOnReload,
        }),
      )
    },
    async receiveMessage(event) {
      if (event?.detail?.message === 'hcpDialog') {
        this.hcpInitialized = true
        this.showHCPModal = true
      }

      if (event?.detail?.message === 'ratingReminder') {
        this.showRatingReminderModal = true
        this.ratingReminderInitialized = true
      }
      if (event?.detail?.message === 'rerouteParent') {
        this.$router.push(event.detail.destination)
      }
      if (event?.detail?.message === 'downloadPdf') {
        const { instance, event, language } = this.$route.params
        const token = this.user.accessToken
        this.openLink(`${window.location.origin}/${instance}/${event}/${language}/pdfDownload?token=${token}`)
      }

      if (event?.detail?.message === 'scrollToTop') {
        this.scrollToTop()
      }
    },
    checkifcontains(parent, child) {
      return parent !== child && parent.contains(child)
    },
    openFloorplanCompany() {
      const link = this.exhibitor ? this.exhibitor.DeepLink : ''
      const url = new URL(link)
      window.open(window.location.origin + url.pathname, '_self')
    },
    setMobileCarousel() {
      const mobileCarousel = this.backendConfig?.defaults?.mobileCarousel
      const mobileCarouselShown = JSON.parse(
        localStorage.getItem('mobileCarouselShown'),
      )
      if (
        mobileCarousel
        && this.$route.name === 'HomePage'
        && mobileCarouselShown?.shown !== true
        && this.isAppContainer
      ) {
        this.showMobileCarousel = true
        this.setMobileCarouselCloseBtn()
      } else {
        this.showMobileCarousel = false
      }
    },
    removeMobileCarouselShown() {
      const mobileCarouselShown = JSON.parse(
        localStorage.getItem('mobileCarouselShown'),
      )
      if (mobileCarouselShown?.showOnReload === true) {
        localStorage.removeItem('mobileCarouselShown')
      }
    },
    waitForCloseBtn(seconds) {
      const ms = seconds * 1000
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    async setMobileCarouselCloseBtn() {
      const { minDuration } = this.backendConfig?.defaults.mobileCarousel
      const { autoCloseMobileCarousel } = this.backendConfig?.defaults.mobileCarousel
      const { showCloseButton } = this.backendConfig?.defaults.mobileCarousel
      if(showCloseButton) {
          this.showMobileCarouselCloseBtn = true
        } 

      if (minDuration) {
        await this.waitForCloseBtn(minDuration)
        if(autoCloseMobileCarousel) {
          this.showMobileCarousel = false
        } else {
          this.showMobileCarouselCloseBtn = true
        }
      }
      this.showMobileCarouselCloseBtn = true
    },
    setAppContainer() {
      this.setIsAppContainer(true)
      this.isAppContainer = true
      localStorage.setItem('isApp', true)

      if (
        this.event === 'dkk2022'
        && JSON.parse(localStorage.getItem('vmx-loaded')) === null
      ) { localStorage.setItem('vmx-loaded', false) }
    },
    setTerminalContainer() {
      this.setIsTerminalContainer(true)
      this.isTerminalContainer = true
      localStorage.setItem('isTerminal', true)
    },
    appendGtag() {
      if (this.gTagScript === null) {
        this.gTagScript = document.createElement('script')
        this.gTagScript.innerHTML = this.backendConfig.defaults.gtagConfig.gtagScript
        document.head.appendChild(this.gTagScript)
      }
    },
    showNetworkingWidgetForPages(networkingActive) {
      let showNetworkingForPages = false
      if (this.NetworkingOnPages.includes(this.$router.currentRoute.name)) {
        showNetworkingForPages = true
      }
      if (
        this.showNetworkingOnHomepage
        && !JSON.parse(localStorage.getItem('networking-popup-loaded'))
        && showNetworkingForPages
      ) {
        if (networkingActive) {
          window.jmeNetworking.openAreaOfInterest({}, document.body)
          localStorage.setItem('networking-popup-loaded', true)
        }
      }
    },
    scrollToTop() {
      window.scrollToTopNew()
    },
    ...mapActions({
      clearErrors: "Error/clearErrors",
      getExhibitorProfileById: "ILPExhibitorProfile/getProfileById",
      getExhibitorProfileByIdV2: 'ILPExhibitorList/getProfileByIdV2',
    }),
    contentSwitch() {
      if (this.superUser) {
        this.$store.commit('instancesState/setSuperUser', false)
        localStorage.removeItem('sUser')
      } else {
        this.$store.commit('instancesState/setSuperUser', true)
        localStorage.setItem('sUser', 'true')
      }
    },
    buildSwitchDev() {
      if (this.vmxDevUrl) {
        window.open(`${this.vmxDevUrl}${this.$route.fullPath}`, '_self')
      }
      else {
        window.open(`https://vmx-dev.m-anage.com${this.$route.fullPath}`, '_self')
      }
    },
    toggleAppContainer() {
      if (localStorage.getItem('isApp') === 'true') {
        localStorage.removeItem('isApp')
        this.isAppContainer = false
        window.open(window.location.origin + window.location.pathname, '_self')
      }
      else localStorage.setItem('isApp', 'true')
      this.isAppContainer = true
      window.open(window.location.origin + window.location.pathname, '_self')
    },
    toggleTerminalContainer() {
      if (localStorage.getItem('isTerminal') === 'true') {
        localStorage.removeItem('isTerminal')
        this.isTerminalContainer = false
        window.open(window.location.origin + window.location.pathname, '_self')
      }
      else localStorage.setItem('isTerminal', 'true')
      this.isTerminalContainer = true
      window.open(window.location.origin + window.location.pathname, '_self')
    },
    hideAppDialogue() {
      localStorage.setItem('appRecommendationSeen', true)
      this.showAppDialogue = false
    },
    toggleAdminOption() {
      this.adminOptionsVisible = !this.adminOptionsVisible
    },
    buildSwitchProduction() {
      if (this.vmxProductionUrl) {
        window.open(`${this.vmxProductionUrl}${this.$route.fullPath}`, '_self')
      }
      else if (config.getDomainConfigWithEvent(this.$route.params.event)) {
        window.open(`https://${config.getDomainConfigWithEvent(this.$route.params.event)}${this.$route.fullPath}`, '_self')
      }
      else {
        window.open(`https://vmx.m-anage.com${this.$route.fullPath}`, '_self')
      }
    },
    forceRerender() {
      this.contentClass();
      this.componentKey += 1;
      this.componentKeyHeader += 1;
    },
    addNetworkingPadding() {
      this.networkingActive = true
    },
    async windowActionTracking(sourceContentAction, entityId, sourceContext) {
      if (sourceContentAction && sourceContext) {
        trackingApi.trackCustomAction(
          sourceContentAction,
          entityId,
          sourceContext,
        )
      }
    },
    onClassChange(classAttrValue) {
      const classList = classAttrValue.split(' ')
      this.splashScreenActive = !!classList.includes('hasSplashscreen')
    },
  },
}
</script>
<style lang="scss">

.logout-user__modal {
  .logout-modal__container {
    width: 100%;
  }
  display: flex;
  .logout_user_content_container {
    display: flex;
    flex-direction: column;
  }
  .logout_user_action_container {
    display: flex;
    justify-content: flex-end;
    gap: .5rem;
    button {
      display: flex;
      align-items: center;
      border: 1px solid #ced4da;
      color: white;
      border-radius: 5px;
      background-color: var(--pag-primary);
      font-weight: 700;
      padding: calc(var(--pag-box-padding) / 4) calc(var(--pag-box-padding) / 2);
      gap: 0.25rem;
      font-size: 15px;
    }
  }
}
.content-toggle-container {
  z-index: 1;
}
#my-toast-cache-refresh {
 .toast-header {
  padding: 0 !important;
 }

 .loader-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
 }

 .loader {
  height: 1rem !important;
  width: 1rem !important;
 }
}
.pag-result-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-top: 3rem;
}
.terminalvideo-container {
  display: none;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
}
.terminalvideo-container.fullscreen {
  display: flex;
  width: 100%;
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0px;
  left: 0px;
}
.terminalvideo-container.fullscreenvid {
  display: block;
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  position: absolute;
  top: 135px;
  left: 0px;
  z-index: 2;
  buttons {
    display: none;
  }
}
.terminalvideo-container video {
    width: 100% !important;
    height: auto;
    position: relative;
    left: 0px;
    top: 115px;
}
body {

  //background:#000 !important;
  #app {
touch-action: pan-x pan-y;
    overflow-y: scroll;
    background: var(--vmx-background-color);
  }
  .mobile_banners_top {
    margin-top: 75px;
    margin-bottom: 75px;
  }

  .bottom-mobile_banner {
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 75px;
    z-index: 4;
  }
  .top-mobile_banner {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 75px;
    z-index: 1;
    margin-bottom: 50px;
  }
  .intro-mobile-modal {
    .ipl-modal__content {
      padding: 0;
      height: 95%;
      width: 95%;
      max-width: 95%;
      margin: 0;
    }
  }
  .floorplanModal-container {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  .floorplanModal-container-row {
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      outline: none;
      border: none;
      margin-top: 10px;
      background-color: #4a5b7c;
      color: white;
      width: 100%;
      padding: 5px;
      font-weight: bold;
    }
  }
  .floorplanModal-item {
    padding: 5px;
    width: 100%;
    height: auto;
    align-self: left;
    margin: 0;
    font-size: 2em;
    text-align: center;
    flex-direction: column;
    &.background {
      background-color: #f8f9fa;
    }
    &.headline {
      font-weight: bold;
      font-size: 25px;
    }
    &.image {
      margin-bottom: 15px;
    }
    &.text {
      font-weight: normal;
      font-size: 16px;
    }
  }
  .floorplanModal {
    .ipl-modal__content {
      max-width: 450px;
      width: auto;
    }
  }
}
</style>
<style lang="scss">
  .evaluation-box__modal {
    .ipl-modal__content {
      padding: .5rem 1rem;

      >div.pag-evaluation {
        width: 100%;

        >div.evaluation-container {
          padding: 0 !important;

          .evaluation-container__header-container:after {
            width: 100%;
          }
        }
        form {
          .evaluation-container__content-container > table > tbody {
            display: block;
          }
          td.evaluation-fiveAnswerRating__question-container__title {
              flex: 0 1 40%;
          }
          .evaluation-fiveAnswerRating__question-container {
              display: flex;
              align-items: center;
          }
          .evaluation-fiveAnswerRating__question-container tr:nth-child(n+2) {
              flex: 0 1 15%;
          }
          .evaluation-fiveAnswerRating__question-container td:nth-child(n+2) {
              flex: 0 1 15%;
          }
          .evaluation-container__content-container {
            display: flex;
            margin: 0 !important;
            gap: 1rem;
            flex-direction: column;

            .evaluation-question-container {
              margin: 0 !important;

              .evaluation-select-answer-container{
                padding-left: 0 !important;

                label {
                  display: flex;
                }
              }
            }

            .evaluation-fiveAnswerRating__header-container {
              display: flex;

               >td:nth-child(1) {
                width: unset;
                flex: 0 1 40%;
               }
               >td:nth-child(n+2) {
                width: unset;
                flex: 0 1 15%;
               }
            }
          }
          .evaluation-container__submit-button {
            margin: 1rem 0;
          }
        }
      }
    }
  }
  .app-recommendation-container {
  position: absolute;
  inset: 0;
  background: #00000070;
  z-index: 2147483601;
}
.app-recommendation-message {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: #fafafa;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: .25rem;
  h4 {
    font-size: 1.25rem;
    font-weight: bold;
  }
  p {
    font-size: .75rem;
  }
}
@keyframes slide-in {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0%);
    }
  }
.app-recommendation-message-fade-in {
  animation: slide-in 1000ms;
}
.app-recommendation-browser,
.app-recommendation-store {
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    width: 50px;
    border-radius: 1rem;
    border: 1px solid var(--primary)
  }
  button,
  a {
    border-radius: 1rem;
  }
}
.admin-options-toggle {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  z-index: 1000000;
}
.content-toggle-container {
  position: fixed;
  bottom: 4rem;
  left: 1rem;
  z-index: 1000000;
  background: #fafafa7e;
  border-radius: 0.25rem;
  border: 2px solid var(--primary);
  padding: .5rem;
}
@media (max-width: 1024px){
  .admin-options-toggle {
    bottom: 5rem;
  }
  .content-toggle-container {
    bottom: 8rem;
}
}
@media (max-width: 768px){
  .content-toggle-container {
   right: 1rem;
}
}

</style>