<template>
  <div>
    <div class="app-navigation-overlay" v-if="isMenuVisible" @click="openMenu" />
    <div :style="appNavigationBackground" class="app-navigation-container">
      <div class="app-navigation-inner-container">
        <div
          v-for="(item, key) in appNavigationBottomItems"
          :key="key"
          @click="componenSwitch(item.function)"
          class="app-navigation-top-toggle"
          :style="combinedStyles"
          :id="item.function.url === 'NotificationsPage' ? 'notification-app-menu' : '' "
        >
          <img
            v-if="'iconImagePath' in item"
            class="app-navigation-top-toggle__icon-image-path"
            :src="item.iconImagePath"
          />
          <font-awesome-icon v-else :icon="['fa', item.icon]" />
          <span>{{ item.name }}</span>
        </div>
        <div
          :class="menuContainerAnimation"
          class="app-navigation-menu-container"
          :style="appMenuContainerBackground"
        >
          <div class="app-navigation-image-container" :style="appNavigationImageContainerStyles">
            <img v-if="appNavigationItems.appMenuContainerLogo" :src="appNavigationItems.appMenuContainerLogo" />
            <div v-else class="app-navigation-header-text" :style="appNavigationTitleContainerStyles">{{ appNavigationItems.appMenuContainerTitle }}</div>
          </div>
          <div class="app-navigation-menu-divider" />
          <div class="app-navigation-menu-items-container">
            <div class="app-navigation-menu-profile" v-if="loginStatus">
              <user-info :welcome-message="welcomeMessage" />
            </div>
            <div class="app-navigation-menu-divider" />

            <div class="app-navigation-menu-items-wrapper">
              <div
                class="app-navigation-menu-items"
                v-for="(item, key) in menuItems"
                :key="key"
              >
                <div @click="action(item.url)" class="app-navigation-menu-item">
                  <span class="app-navigation-menu-item-name">
                    {{ item.name }}
                  </span>
                  <div v-if="'iconImagePath' in item">
                    <img
                      class="app-navigation-menu-item-name__icon-image-path"
                      :src="item.iconImagePath"
                    />
                  </div>
                  <span v-else>
                    <font-awesome-icon :icon="['fa', item.icon]" />
                  </span>
                </div>
              </div>
            </div>
            <div class="app-navigation-menu-divider" />

            <div class="app-navigation-login">
              <template v-if="loginStatus">
                <div @click.prevent="logout">
                  {{ $t("VMX.user_logout") }}
                  <font-awesome-icon :icon="['fas', 'fa-right-from-bracket']" />
                </div>
              </template>
              <template v-else>
                <div @click.prevent="login">
                  {{ $t("VMX.user_login") }}
                  <font-awesome-icon :icon="['fas', 'fa-right-to-bracket']" />
                </div>
              </template>
            </div>
            <div class="app-navigation-legal-container">
              <div class="app-navigation-legal">
                <div
                  @click="action(appNavigationItems.legal.url)"
                >
                  {{ appNavigationItems.legal.name }}
                  <font-awesome-icon :icon="['fa', appNavigationItems.legal.icon]" />
                </div>
              </div>
              <div class="app-navigation-privacy">
                <div
                  @click="action(appNavigationItems.privacy.url)"
                >
                  {{ appNavigationItems.privacy.name }}
                  <font-awesome-icon :icon="['fa', appNavigationItems.privacy.icon]" />
                </div>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import EventBus from '~/states/event-bus'

import userState from '~/states/user';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import headerMixins from '~/mixins/headerMixins';
import utils from '~/utilities/utils';
import config from '~/config';
import backButtonMixins from '~/mixins/backButtonMixins';
import appContainerMixins from 'm-anage.com.vmx-components/src/mixins/appContainerMixins'

import UserInfo from './UserInfo.vue';
Vue.use(VueCookies);

export default {
  name: 'AppNavigation',
  inject: {
    TrackingUtil: {
      type: Function,
    },
  },
  mixins: [headerMixins, backButtonMixins, appContainerMixins],
  components: {
    UserInfo,
  },
  data() {
    return {
      isMenuVisible: false,
      menuContainerAnimation: '',
      menuIconAnimation: '',
    };
  },
  props: {
    appNavigationItems: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters({
      instance: 'instancesState/getInstance',
      event: 'instancesState/getEvent',
      language: 'instancesState/getLanguage',
      configForKey: 'config/configForKey',
      user: 'user/user',
      history: 'instancesState/getHistory',
    }),
    signalRConnection() {
      return this.configForKey('app_config').config[0].AccountHub?.signalRConnection ? this.configForKey('app_config').config[0].AccountHub.signalRConnection : false
    },
    signalRConnectionAmount() {
      return this.configForKey('app_config').config[0].AccountHub?.signalRConnectionAmount ? this.configForKey('app_config').config[0].AccountHub.signalRConnectionAmount : 0
    },
    openSignalRModal() {
      return this.configForKey('app_config').config[0].AccountHub?.openSignalRModal ? this.configForKey('app_config').config[0].AccountHub.openSignalRModal : false
    },
    backendConfig() {
      return this.configForKey('app_config').config[0]
    },
    disableBackLogicForAppMenu() {
      const config = this.$store.getters['config/configForKey']('app_config')?.configV2?.defaultV2
      if (config && 'disableBackLogicForAppMenu' in config) {
        return config.disableBackLogicForAppMenu
      }
      return false
    },
    appNavigationBottomItems() {
      let newNavigation = this.appNavigationItems.bottomItems
      if(!this.disableBackLogicForAppMenu && !(this.history.length > 1)) {
        newNavigation = this.appNavigationItems.bottomItems.filter(item => item.name !== 'Back')
      }

      return newNavigation
    },
    externalAuth() {
      if (this.backendConfig.defaults?.externalAuth) {
        return this.backendConfig.defaults.externalAuth.externalLogin
      }
      return false
    },
    loginStatus() {
      return !!userState.getUser(this.instance);
    },
    appMenuItems() {
      return this.appNavigationItems.appMenuItems
    },
    welcomeMessage() {
      return this.appNavigationItems.welcomeMessage
    },
    appNavigationBackground() {
      const styles = `background-color: ${
        this.appNavigationItems.appNavigationBackground
          ? this.appNavigationItems.appNavigationBackground
          : 'var(--primary)'
      }; border-radius: ${
        this.appNavigationItems.appNavigationBorderRadius
          ? this.appNavigationItems.appNavigationBorderRadius
          : '.5rem'
      }; border: ${
        this.appNavigationItems.appNavigationBorder
          ? this.appNavigationItems.appNavigationBorder
          : ''
      }; bottom: ${
        this.appNavigationItems.appNavigationBottomSpacing
          ? this.appNavigationItems.appNavigationBottomSpacing
          : ''
      };  box-shadow: ${
        this.appNavigationItems.appNavigationBoxShadow
          ? this.appNavigationItems.appNavigationBoxShadow
          : ''
      }; `
      return styles
    },
    appMenuContainerBackground() {
      const styles = `background-color: ${
        this.appNavigationItems.appMenuContainerBackground
          ? this.appNavigationItems.appMenuContainerBackground
          : 'var(--primary)'
      }; border-radius: ${
        this.appNavigationItems.appMenuContainerBorderRadius
          ? this.appNavigationItems.appMenuContainerBorderRadius
          : '.5rem'
      }; `;
      return styles
    },
    appNavigationImageContainerStyles() {
        const styles = `border-radius: ${
        this.appNavigationItems.appNavigationImageContainerBorderRadius
          ? this.appNavigationItems.appNavigationImageContainerBorderRadius
          : '.5rem .5rem 0 0'
      }; `
      return styles
    },
    appNavigationTitleContainerStyles() {
      let styles = {}
      if (this.appNavigationItems.appNavigationTitleContainerStyles) {
        styles = this.appNavigationItems.appNavigationTitleContainerStyles
      }
      return styles
    },
    combinedStyles() {
      const itemStyles = `flex: 0 1 ${100 / this.appNavigationBottomItems.length}%`;
      const appMenuToggle = `color: ${
        this.appNavigationItems.appMenuToggle
          ? this.appNavigationItems.appMenuToggle
          : '#fff'
      }`;
      return `${itemStyles}; ${appMenuToggle}`;
    },
    menuItems() {
      const items = this.appNavigationItems?.items;
      if (items) {
        return items;
      }
      return [];
    },
  },
  created() {
    if (this.signalRConnection) {
      this.$accountHub.$on('remote-logout', (params) => {

        if('uuid' in params && 'modal' in params && this.openSignalRModal && userState.getUser(this.instance).uuid === params.uuid && params.modal) {
          this.emitOpenSignalRModal()
        } else {
          this.logout()
        }
      })

      this.$root.$on('logout-user-device', async () => {
        await this.$accountHub.startSignalRAccount(this.instance, this.event, userState.getUser(this.instance).accessToken, this.signalRConnectionAmount, false, userState.getUser(this.instance).uuid)
        this.emitCloseSignalRModal()
        this.logout()
      })

      this.$root.$on('continue-user-device', async () => {
        await this.$accountHub.startSignalRAccount(this.instance, this.event, userState.getUser(this.instance).accessToken, this.signalRConnectionAmount, true, userState.getUser(this.instance).uuid)
        this.emitCloseSignalRModal()
      })
    }
    
  },
  mounted() {
    if (userState.getUser(this.instance)?.accessToken && this.signalRConnection) {
      this.$accountHub.startSignalRAccount(this.instance, this.event, userState.getUser(this.instance).accessToken, this.signalRConnectionAmount, null, userState.getUser(this.instance).uuid)
    }
  },
  methods: {
     ...mapActions({
      deleteHistory: 'instancesState/removeHistory',
    }),
    parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    },
    emitOpenSignalRModal() {
      this.$root.$emit('open-signalr-logout-modal')
    },
    emitCloseSignalRModal() {
      this.$root.$emit('close-signalr-logout-modal')
    },
    componenSwitch(params) {
      switch (params.method) {
        case 'url':
          {
            this.action(params.url)
            return
          }
        case 'scroll':
          {
            this.hapticFeedback('medium')
            this.scrollToTop()
            return
          }
        case 'menu':
          {
            this.hapticFeedback('medium')
            this.openMenu()
             return
          }
        case 'back':
          {
            this.hapticFeedback('medium')
            this.goBack()
             return
          }
      }
    },
    openMenu() {
      this.isMenuVisible = !this.isMenuVisible
      if (this.isMenuVisible === true) {
        this.menuContainerAnimation = 'slide-in-top'
        this.menuIconAnimation = 'rotate'
      } else {
        this.menuContainerAnimation = 'slide-out-top'
        this.menuIconAnimation = 'rotate-reverse'
      }
    },
    closeMenu() {
      if (this.isMenuVisible) {
        this.menuContainerAnimation = 'slide-out-top'
        this.menuIconAnimation = 'rotate-reverse'
        this.isMenuVisible = false
      } else {
        this.menuContainerAnimation = ''
        this.menuIconAnimation = ''
      }
    },
    login() {
      // this.tracking('login')
      userState.unsetUser(this.instance);
      this.$store.commit('instancesState/setToken', null);

      if (
        this.backendConfig.defaults?.forceLoginPage ||
        (localStorage.getItem('isApp') === 'true' &&
          this.backendConfig.defaults?.forceLoginPageApp)
      ) {
        this.$router.push(utils.getRouteWithDefaultParams('LoginPage'));
        return;
      }
      const instanceConfig = config.getInstanceConfig(this.instance);
      let returnPath = this.$route.path;
      if (
        this.$route.name === 'AuthPage' ||
        this.$route.name === 'NoaccessPage'
      ) {
        returnPath = `/Home/${this.instance}/${this.event}/${this.language}`;
      }

      const redirectUri = new URL(window.location.origin + returnPath);
      if (
        this.backendConfig.SSOLogin &&
        Object.prototype.hasOwnProperty.call(instanceConfig, 'sso')
      ) {
        const alternativePath = this.backendConfig?.SSOReturnUrl
          ? this.backendConfig.SSOReturnUrl
          : null;
        const { identityUrl } = instanceConfig;
        const loginUrl = instanceConfig.sso.url;
        const prevQuery = utils.queryString(this.$route.query);
        const defaultParams = {
          shortname: this.event,
          language: this.language,
          returnUrl:
            window.location.origin +
            (alternativePath || this.$route.path) +
            '?' +
            prevQuery,
        };
        const defaultQueryStrings = utils.queryString(defaultParams);

        if (
          Object.prototype.hasOwnProperty.call(instanceConfig.sso, 'context')
        ) {
          Object.assign(defaultParams, { context: instanceConfig.sso.context });
        }
        if (
          Object.prototype.hasOwnProperty.call(instanceConfig.sso, 'provider')
        ) {
          Object.assign(defaultParams, {
            provider: instanceConfig.sso.provider,
          });
        }

        let SSOUrl = `${identityUrl + loginUrl}?${defaultQueryStrings}`;

        if (this.externalAuth) {
          const externalLoginUrl =
            this.backendConfig.defaults.externalAuth.loginUrl;
          const externalLoginParams = {
            returnUrl:
              window.location.origin +
              (alternativePath || this.$route.path) +
              '?' +
              prevQuery,
          };
          const externalLoginQueryStrings =
            utils.queryString(externalLoginParams);
          SSOUrl = `${externalLoginUrl}${
            externalLoginUrl.includes('returnUrl')
              ? ''
              : '&' + externalLoginQueryStrings
          }`;
        }

        localStorage.setItem('externalLogin', true);

        window.location.href = SSOUrl;
        return;
      }

      const params = {
        returnUrl: redirectUri,
      };
      const queryString = utils.queryString(params);
      const url = `${instanceConfig.webformsUrl}/Home/GetToken?${queryString}`;
      localStorage.setItem('tryLogin', '123');
      if (!config.getInstanceConfig(this.instance).identityUrl) {
        utils.redirectOAuthAuthorize(this.$route);
        return;
      }

      if (this.externalAuth) {
        if (this.backendConfig.defaults.externalAuth.loginUrl) {
          // window.location.href = `${webFormsUrl + logoutUrl}?${queryStrings}`
          window.location.href =
            this.backendConfig.defaults.externalAuth.loginUrl;
          return;
        }
      }
      if (this.$route.name !== 'LoginPage') {
        window.location.href = url;
      }
    },
    logout() {
      // this.tracking('logout')
      this.$cookies.remove('accesOnDays');
      this.$cookies.remove('tickets');
      this.$cookies.remove('hcp');
      userState.unsetUser(this.instance);
      this.$store.commit('instancesState/setToken', null);
      localStorage.removeItem('externalLogin');
      localStorage.removeItem('recheckedUserTicket');
      const webFormsUrl = config.getInstanceConfig(this.instance).webformsUrl;
      const params = {
        eventName: this.event,
      };
      const queryStrings = utils.queryString(params);
      let logoutUrl = '/Main/Logout';

      if (this.backendConfig.defaults.externalAuth) {
        if (this.backendConfig.defaults.externalAuth.logoutUrl) {
          logoutUrl = this.backendConfig.defaults.externalAuth.logoutUrl;
          // window.location.href = `${webFormsUrl + logoutUrl}?${queryStrings}`
          window.location.href = logoutUrl;
          return;
        }
      }
      // this.$router.push(utils.getRouteWithDefaultParams('LoginPage'))
      window.location.href = `${webFormsUrl + logoutUrl}?${queryStrings}`;
    },
    action(url) {
      this.hapticFeedback('medium')
      const pages = ['PosterPage', 'IndustrySymposiaPage', 'PresentationSearchPage', 'FacultySearchPage', 'PagSearchPage', 'AbstractSearchPage']
      if (pages.includes(this.$route.name)) {
        this.$root.$emit('rerender')
      }
      
      if ((url.dataType === 'link' || url.dataType === 'qnalink') && this.isApp) {
        if (url.dataType === 'qnalink') {
          if(Vue.$cookies.get('access_token')) {
            window.open(`${url.href}?access_token=${Vue.$cookies.get('access_token')}&user_id=${Vue.$cookies.get('user_id')}&event_id=${Vue.$cookies.get('event_id')}&isApp=${this.isApp}&returnUrl=${window.location.href}`, '_self')
          } else {
            window.open(`${url.href}/${url.qnaInstance}?isApp=${this.isApp}&returnUrl=${window.location.href}`, '_self')
          }
          return
        }
        window.openLink(url.href)
        return
      }

      if (url.dataType === 'qnalink') {
        window.open(`${url.href}?access_token=${Vue.$cookies.get('access_token')}&user_id=${Vue.$cookies.get('user_id')}&event_id=${Vue.$cookies.get('event_id')}&isApp=${this.isApp}&returnUrl=${window.location.href}`, '_blank')
      }

      if (utils.isUrl(url)) {
        window.open(url, '_self')
        return
      } else {
        let route = null;
        if (utils.isObject(url)) {
          route = utils.appendDefaultParamsToRoute(
            url
          )
        } else {
          route = utils.getRouteWithDefaultParams(
            url
          );
        }
        this.closeMenu()
        this.$router.push(route)
      }
    },
    icon(icon) {
      // eslint-disable-next-line prefer-const
      let [first, ...rest] = icon.split('-');
      rest = rest.join('-');
      if (first && rest) {
        return [first, rest];
      }
      return ['fa', 'question'];
    },
    scrollToTop() {
      const app = document.getElementById('app');
      app.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    goBack() {
      this.closeMenu()
      if(this.history.length > 1) {
        EventBus.$emit('historyBack', true)
        this.deleteHistory()
        this.$router.go(-1)
      } else {
        window.history.back()
      }
    },
  },
}
</script>
<style lang="scss">

  .app-navigation-menu-item-name__icon-image-path {
    height: 2em;
    overflow: visible;
    vertical-align: -0.125em;
    width: unset !important;
    display: unset important;
    margin: unset !important;
  }

  .app-navigation-top-toggle__icon-image-path {
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
    width: unset !important;
    display: unset important;
    margin: unset !important;
  }
</style>

